import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';
import { routerMiddleware } from 'react-router-redux';

const configureStore = (preloadedState, history) => {
    const middlewares = [thunk, routerMiddleware(history)];

    if (process.env.NODE_ENV === 'development'){
        middlewares.push(createLogger());
    }

    const composed = [applyMiddleware(...middlewares)];

    if (process.env.NODE_ENV === 'development'){
        const devExtension = window._REDUX_DEVTOOLS_EXTENSION_ && 
                             window._REDUX_DEVTOOLS_EXTENSION_();

        if (devExtension){
            composed.push(devExtension);
        }
    }

    const store = createStore(rootReducer, preloadedState, compose(...composed));

    return store;
}

export default configureStore;