import axios from 'axios';
import { resolveFromBaseURL } from './config';
import { LocalStorage } from '../../helpers/storage';

/**
 * Core request handler.
 */
const request = (method, url, data, options = {}, upload = false) => {
  const headers = {};
  const token = LocalStorage.get('token');

  if (!options.public && token) {
    headers['Authorization'] = `Bearer ${token}`;
    headers['ELECTRONAPI'] = LocalStorage.get('account_access_token');
  }

  axios.defaults.timeout = 60 * 10 * 1000;

  return axios({
    // Set url.
    url: options.external ? url : resolveFromBaseURL(url),

    // Set method.
    method,

    // Set params if method is get. otherwise set data.
    [method === 'get' ? 'params' : 'data']: data,

    // Set credentials true.
    withCredentials: false,

    // Headers
    headers,

    // Add custom flag.
    SUPPRESS_ERROR: options.SUPPRESS_ERROR,
  }).then(response => {
    if (response === undefined || response === null) return { success: false };

    return options.raw ? response : response.data;
  });
};
/**
 * Head
 */
export const head = (url, params, options) => request('head', url, params, options);

/**
 * Get
 */
export const get = (url, params, options) => request('get', url, params, options);

/**
 * Post
 */
export const post = (url, data, options) => request('post', url, data, options);

/**
 * Put
 */
export const put = (url, data, options) => request('put', url, data, options);

/**
 * Delete
 */
export const remove = (url, data, options) => request('delete', url, data, options);

/**
 * Upload
 */
export const upload = (url, data, options) => request('post', url, data, options, true);

/**
 * Export methods.
 */
export const interceptors = axios.interceptors;

interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.config.SUPPRESS_ERROR) {
      return Promise.reject(error);
    }

    if (error.response === undefined) {
    } else {
      if (error.response.status === 401 || error.response.status === 422) {
        LocalStorage.clear();
        window.location.href = '/login';
      } else {
        // error.config.data.password = 'xxx';
        // error.response.config.data.password = 'xxx';
      }

      // _LTracker.push({
      //   response: {
      //     status: error.response.status,
      //     data: error.response.data,
      //     responseURL: error.request.responseURL,
      //   },
      //   request: {
      //     data: error.config.data,
      //   },
      //   error: {
      //     stack: error.stack,
      //     message: error.message,
      //   },
      // });
    }
  },
);
