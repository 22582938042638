const { hostname } = window.location;

let api_url = 'https://yp7oypmfx5.execute-api.eu-central-1.amazonaws.com/production';
let auth_url = 'https://k29xqt65vg.execute-api.eu-central-1.amazonaws.com/production';
let ml_url = 'https://electron.api.monoanalytics.com';

if (process.env.REACT_APP_TEST === "true") {
  ml_url = "https://test-electron-api.monoanalytics.com"
  api_url = "https://0j0q35xpq5.execute-api.eu-central-1.amazonaws.com/staging"
}


const config = {
  authUrl: auth_url,
  mlUrl: ml_url,
  baseUrl: api_url,
  imageUrl: '',
};

export const getEnv = () =>
  hostname === 'localhost' || hostname.indexOf('test') === 0 ? 'test' : 'prod';

export const isTestEnv = () => getEnv() === 'test';

export const resolveFromBaseURL = path => config.baseUrl + path;

export default config;
