import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { setMinutes, setHours } from 'date-fns'
import socketIOClient from "socket.io-client";
import Notification from 'react-web-notification';
import { LocalStorage } from '../helpers/storage';
import config from '../api/core/config';
import {
  Segment,
  Dropdown,
  Button,
  Icon,
  Grid,
  Input,
  Container,
  Table,
  Header,
  Message,
  Image,
  Label,
  Modal,
  Checkbox
} from 'semantic-ui-react';
import Chart from 'chart.js/auto';
import ReactExport from 'react-export-excel';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import MonoHeader from './common/MonoHeader';
import Footer from './common/Footer';
import Alert from './common/Alert';
import { fetchUser } from '../actions/user';
import { fetchResourceSettings } from '../actions/settings';

import {
  onChangeText,
  historicalDataCheck,
  historicalDataOutageCheck,
  fetchResult,
  sendForecastResultEmail,
} from '../actions/forecast';
import { checkPageAuth } from '../actions/auth';
import { errors, messages } from '../constants';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class DemandFc extends Component {

  constructor(props) {
    super(props);
    this.chartRef = React.createRef();

    this.state = {
      ignore: true,
      title: '',
      loading: true,
      auth: false,
      resourceId: 0,
      pipeline_type: '',
      resources: [],
      startDate: new Date(),
      fileHistorical: { name: '' },
      fileOutage: { name: '' },
      fcColumns: [],
      fcResult: [],
      show: false,
      forecast_result_id: -1,
      lastDay: null,
      missingDays: '',
      futureOutage: '',
      result: [],
      excelData: [],
      openModal: false,
      modalMessage: '',
      modalType: '',
      overrideHeader: '',
      historicals: [],
      logo: '',
      forecast_loading: false,
      forecast_days: 0,
      totalDemands: []
    };

    this.datepickerRef = React.createRef();
    this.chartReference = React.createRef();

    this.runForecast = this.runForecast.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }

  handlePermissionGranted() {
    this.setState({
      ignore: false
    });
  }
  handlePermissionDenied() {
    this.setState({
      ignore: true
    });
  }
  handleNotSupported() {
    this.setState({
      ignore: true
    });
  }

  handleNotificationOnClick(e, tag) {
  }

  handleNotificationOnError(e, tag) {
  }

  handleNotificationOnClose(e, tag) {
  }

  handleNotificationOnShow(e, tag) {
  }

  showNotification() {

    if (this.state.ignore) {
      return;
    }

    const title = 'Electron | Forecast Result';
    const body = 'Merhaba, ' + moment(this.state.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD') + ' tarihli tahmininiz hazır. Sonuçları inceleyebilirsiniz.'
    const tag = 'electron, mono, forecast';
    const icon = 'https://www.electron.monoanalytics.com/favicon.png';
    // Available options
    // See https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification
    const options = {
      tag: tag,
      body: body,
      icon: icon,
      lang: 'tr',
      dir: 'ltr'
    }

    this.setState({
      title: title,
      options: options
    });
  }

  closeModal = () => {
    this.setState({ openModal: false, modalMessage: '' });
  };

  componentDidMount() {
    const { t } = this.props;

    this.props.checkPageAuth('demandfc').then(r => {
      this.setState({
        auth: r.result, loading: false,
        fcColumns: [t('hour'), t('demand.day'), t('demand.d1'), t('demand.d2'), t('demand.d3')],
        excelData: [{
          columns: [t('hour'), t('demand.day'), t('demand.d1'), t('demand.d2'), t('demand.d3')],
          data: [],
        },]
      })
      const { result } = this.state;
      if (this.state.result.length === 0) {
        for (let i = 1; i < 25; i++) {
          result.push(['', '', '', '']);
        }
      }

      if (r.result) {
        this.props.fetchUser().then(data => {
          this.getResourceSettings(data.account.resources[0].id);
          this.checkHistoricalDataOutage(data.account.resources[0].id);
          this.checkHistoricalData(data.account.resources[0].id);
          this.setState({
            resources: data.account.resources,
            logo: data.account.account_settings.logo,
          });

          const socket = socketIOClient(config.mlUrl, { transports: ['websocket'] });

          socket.on(`result_${LocalStorage.get('user_external_id')}`, response => {
            this.showNotification()
            if (response.success) {
              if (response.predictions && response.predictions.length > 0) {
                const result = [];
                const columns = [];
                const dateFilters = [];
                columns.push(t('hour'));
                response.predictions.forEach(x => {
                  if (columns.indexOf(moment(x.time).format('DD-MM-YYYY')) === -1) {
                    columns.push(moment(x.time).format('DD-MM-YYYY'));
                    dateFilters.push(x.time);
                  }
                });

                for (let i = 0; i < 24; i++) {
                  const lineData = [];
                  dateFilters.forEach(f => {
                    const val = response.predictions
                      .filter(x => x.hour === i && x.time === f)
                      .sort(x => x.time)
                      .map(p => Number.parseFloat(p.predict).toFixed(2));

                    if (val.length > 0) {
                      lineData.push(val[0]);
                    }
                    else {
                      lineData.push(0);
                    }
                  });
                  result.push(lineData);
                }

                const totalDemands = [];
                dateFilters.forEach(f => {
                  const val = response.predictions
                    .filter(x => x.time === f)
                    .map(p => Number.parseFloat(p.predict).toFixed(2) * 1);

                  const arrSum = val.reduce((a, b) => a + b, 0);
                  totalDemands.push(arrSum);
                });

                const excelData = this.createExcelData(columns, result);
                this.createChart(response.predictions, response.historicals, dateFilters);

                this.setState({
                  fcResult: { data: response.predictions },
                  result,
                  totalDemands,
                  columns,
                  excelData,
                  forecast_result_id: response.forecast_result_id,
                  forecast_loading: false,
                });
              } else {
                this.setState({ openModal: true, overrideHeader: t(errors.runForecastErrorTitle), modalType: 'error', modalMessage: t(errors.runForecastErrorMessage), forecast_loading: false });
                this.setState({ ...this.state });
              }
            } else {
              this.setState({ openModal: true, modalType: 'error', overrideHeader: t(errors.runForecastErrorTitle), modalMessage: t(errors.runForecastErrorMessage), forecast_loading: false });
              this.setState({ ...this.state });
            }
          });

          socket.on(`run_${LocalStorage.get('user_external_id')}`, response => {
            if (response.running) {
              this.setState({
                openModal: true,
                modalType: 'info',
                modalMessage: t(messages.alreadyRun),
                overrideHeader: t('alreadyRunHeader')
              });
            }
          });
        });
      }

    });

  }

  getResourceSettings(resourceId) {
    const { i18n } = this.props;
    this.props.fetchResourceSettings(resourceId).then(result => {
      if (result.success) {
        i18n.changeLanguage(result.lang);
        this.setState({
          forecast_days: result.forecast_date_range,
          resourceId: resourceId
        })
      }
    });
  }

  checkHistoricalData = resourceId => {
    this.setState({ loading: true });
    this.props.historicalDataCheck(resourceId).then(result => {
      if (result.success) {
        const minDate = result.last_day ? new Date(result.last_day) : new Date();
        const selectedDate = new Date(minDate.setHours(minDate.getHours() + 1));
        this.setState({
          lastDay: result.last_day,
          loading: false,
          startDate: selectedDate,
          missingDays: result.missing_days,
        });
      } else {
        this.setState({ loading: false, lastDay: null, startDate: new Date(), missingDays: '' });
      }
    });
  };

  checkHistoricalDataOutage = resourceId => {
    this.props.historicalDataOutageCheck(resourceId).then(result => {
      if (result.success) {
        this.setState({
          futureOutage: result.future_outages,
        });
      } else {
        this.setState({ futureOutage: '' });
      }
    });
  };

  runForecast = () => {
    const { startDate, forecast_days } = this.state;
    // alert('Forecast sonuçları hazırlanıyor. Lütfen bekleyiniz');
    this.setState({ forecast_loading: true });
    this.props
      .fetchResult(
        this.props.id,
        moment(startDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
        this.state.resourceId,
        this.state.resources.find(f => f.id === this.state.resourceId).pipeline_type,
        forecast_days
      )
  };

  createExcelData = (columns, data) => {
    const csvData = [];

    data.forEach((r, index) => {
      const row = [];
      row.push(index);
      r.forEach(x => {
        row.push(parseFloat(x).toFixed(2) * 1);
      });
      csvData.push(row);
    });

    return [{ columns, data: csvData }];
  };

  createChart = (data, historical, dateFilters) => {
    const { t } = this.props;
    const data_labels = [];
    const datas = [];
    const historicals = [];
    const historical_labels = [];

    dateFilters.forEach(f => {
      const val = data
        .filter(x => x.time === f)
      for (let i = 0; i < val[0].hour; i++) {
        data_labels.push(`${f} ${i}:00:00`);
        datas.push(0);
      }
      val.forEach(x => {
        data_labels.push(`${x.time} ${x.hour}:00:00`);
        datas.push(x.predict.toFixed(2));
      });
    });

    historical.forEach(x => {
      historical_labels.push(`${x.time} ${x.hour}:00:00`);
      historicals.push(x.predict.toFixed(2));
    })
    const myChartRef = this.chartRef.current.getContext('2d');
    if (this.myChart) {
      this.myChart.destroy();
    }
    this.myChart = new Chart(myChartRef, {
      type: 'line',
      data: {
        //Bring in data
        labels: data_labels,
        datasets: [
          {
            labels: data_labels,
            label: t('demand.chartForecastTitle'),
            data: datas,
            borderColor: 'teal',
            backgroundColor: 'rgba(0, 0, 0, 0.01)',
          },
          {
            labels: historical_labels,
            label: t('demand.chartPastTitle'),
            data: historicals,
            borderColor: 'red',
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
          }
        ],
      },
      options: {
        scales: {
          xAxes: [
            {
              ticks: {
                labelOffset: 100,
                callback: function (value, index, values) {
                  if (index % 24 === 0 && index < 96) return value.split(' ')[0];
                  else return null;
                },
              },
            },
          ],
          yAxes: [{
            ticks: {
              beginAtZero: false
            }
          }]
        },
        legend: {
          display: true,
          labels: {
            fontColor: 'grey',
          },
        },
        tooltips: {
          yAlign: 'bottom',
          custom: function (tooltip) {
            if (!tooltip) return;
            // disable displaying the color box;
            tooltip.displayColors = false;
            tooltip.titleMarginBottom = 100
            tooltip.titleSpacing = 100

          },
          callbacks: {
            label: function (tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex];
              var index_d = tooltipItem.datasetIndex === 1 ? 0 : 1
              var o_dataset = data.datasets[index_d];
              var index = tooltipItem.index;
              var title = []
              var first = tooltipItem.datasetIndex === 0 ? t('forecastDate') : t('lastWeek')
              var second = tooltipItem.datasetIndex === 1 ? t('forecastDate') : t('lastWeek')
              title.push(first + dataset.labels[index] + ' - ' + t('data') + ' : ' + dataset.data[index])
              title.push(second + o_dataset.labels[index] + ' - ' + t('data') + ' : ' + o_dataset.data[index])
              return title
            },
            title: function (tooltipItem, data) {
              return;
            }
          }
        }
      },
    });

    this.myChart.update();
  };

  sendEmail() {
    this.setState({ loading: true });
    const { forecast_result_id } = this.state;
    const account_id = this.props.id;
    this.props
      .sendForecastResultEmail(account_id, forecast_result_id)
      .then(response => {
        this.setState({
          openModal: true,
          modalType: 'success',
          modalMessage: response.message,
          loading: false,
        });
      })
      .catch(err => {
        this.setState({ openModal: true, modalType: 'error', modalMessage: err, loading: false });
      });
  }

  render() {
    const { resources, t, i18n } = this.props;
    const {
      loading,
      auth,
      fcColumns,
      fcResult,
      startDate,
      lastDay,
      missingDays,
      futureOutage,
      forecast_result_id,
      excelData,
      columns,
      result,
      openModal,
      modalType,
      modalMessage,
      forecast_loading,
      overrideHeader,
      totalDemands
    } = this.state;

    const forecastMinDate = moment(new Date(lastDay)).toDate();

    let resourceList = [];
    if (resources) {
      resources
        .forEach((r, index) => {
          resourceList.push({
            key: r.id,
            text: r.name,
            value: r.id,
          })
        }
        );
    }
    return (
      <div className="wrapper">
        <MonoHeader history={this.props.history} activeItem="demandfc" />

        <div className="h20" />

        <Segment loading={loading} style={{ width: '100%', minHeight: window.screen.height - 400 }}>

          {
            !auth && !loading && (
              <Message error>
                <Message.Header>{t('auth')}</Message.Header>
                <p>{t('authMessage')}</p>
              </Message>
            )
          }

          {
            auth && (
              <Grid columns={2} relaxed="very" stackable>
                <Grid.Column>
                  <Segment raised>
                    <div className="row-container">
                      <div className="wp30">
                        <Grid columns={1} divided>
                          <Grid.Column width={10}>
                            {this.state.logo && (<Image src={this.state.logo} style={{ width: 100, objectFit: 'contain' }} />)}
                          </Grid.Column>
                        </Grid>
                      </div>
                      <div className="wp70">
                        <Dropdown
                          placeholder={t('resource')}
                          fluid
                          search
                          selection
                          value={this.state.resourceId}
                          options={resourceList}
                          onChange={(e, { value }) => {
                            this.setState({ resourceId: value });
                            this.checkHistoricalData(value);
                            this.getResourceSettings(value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="h20" />

                    <div className="row-container">
                      <div className="wp30">{t('demand.startDate')}:</div>
                      <div className="wp70">
                        <DatePicker
                          selected={startDate}
                          onChange={date =>
                            this.setState({
                              startDate: date,
                            })
                          }
                          minDate={moment(new Date(lastDay))}
                          minTime={setMinutes(forecastMinDate, 60)}
                          maxTime={setHours(setMinutes(forecastMinDate, 45), 23)}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={60}
                          timeCaption={t('hour')}
                          dateFormat="dd-MM-yyyy HH:mm"
                          style={{ backgroundColor: 'green', width: '100%' }}
                          customInput={
                            <Input
                              className="wp100"
                              icon="calendar times"
                              iconPosition="left"
                              value={startDate}
                            />
                          }
                        />
                      </div>
                    </div>

                    <div className="h20" />

                    <div>{t('demand.scope')} :
                      <Checkbox
                        radio
                        label={t('demand.one')}
                        name='checkboxRadioGroup'
                        value={true}
                        checked={this.state.forecast_days === 1}
                        onChange={e => this.setState({ forecast_days: 1 })}
                      />

                      <Checkbox
                        radio
                        label={t('demand.two')}
                        name='checkboxRadioGroup'
                        value={false}
                        checked={this.state.forecast_days === 2}
                        onChange={e => this.setState({ forecast_days: 2 })}
                      />

                      <Checkbox
                        radio
                        label={t('demand.three')}
                        name='checkboxRadioGroup'
                        value={false}
                        checked={this.state.forecast_days === 3}
                        onChange={e => this.setState({ forecast_days: 3 })}
                      />

                      <Checkbox
                        radio
                        label={t('demand.four')}
                        name='checkboxRadioGroup'
                        value={false}
                        checked={this.state.forecast_days === 4}
                        onChange={e => this.setState({ forecast_days: 4 })}
                      />
                    </div>
                    {lastDay && (
                      <Message warning>
                        <Message.Header>{t('demand.smartNotify')}</Message.Header>
                        <p>
                          {t('demand.lastData').replace("###DATA###", moment(lastDay).format('DD-MM-YYYY HH:mm'))}
                        </p>
                      </Message>
                    )}

                    {futureOutage && (
                      <Message warning>
                        <Message.Header>{t('demand.smartNotify')}</Message.Header>
                        <p>{t('demand.outageNotify')}</p>
                        <p>{futureOutage.map(x => x + ' | ')}</p>
                      </Message>
                    )}
                    {/* <div className="h20" /> */}

                    {missingDays && (
                      <Message error>
                        <Message.Header>{t('demand.missingHeader')}</Message.Header>
                        <p>{t('demand.missingBody')}</p>
                        <p>{missingDays.map(x => x + ' | ')}</p>
                      </Message>
                    )}

                    <div className="h20" />

                    <Container textAlign="center">
                      <Button primary size="huge" onClick={() => this.runForecast()}>
                        {t('run')}
                      </Button>
                    </Container>

                    <div className="h40" />

                    <canvas id="myChart" ref={this.chartRef} />
                  </Segment>
                </Grid.Column>

                <Grid.Column>
                  <Segment raised>

                    <Header as="h2" style={{ marginBottom: -50 }}>
                      <p>Forecast (MWh)</p>
                      <Label color='teal' ribbon='right' className='download-label' as='a'>
                        {fcResult && fcResult.data && fcResult.data.length > 0 && (
                          <ExcelFile
                            element={
                              <Button
                                icon
                                labelPosition="right"
                                color="teal"
                                size='small'>
                                {t('download')}
                                <Icon name="angle down" />
                              </Button>
                            }
                            filename={`DemandForecast_${moment(startDate).format('DD-MM-YYYY')}`}
                          >
                            <ExcelSheet dataSet={excelData} name={t('csvName')} />
                          </ExcelFile>
                        )}
                        {!(fcResult && fcResult.data && fcResult.data.length > 0) && (
                          <Button
                            size='small'
                            color="teal"
                            icon
                            disabled={!(fcResult && fcResult.length > 0)}
                          >
                            {t('download')}
                            <Icon name="angle down" />
                          </Button>
                        )}
                      </Label>
                    </Header>

                    <Table celled selectable>
                      <Table.Header className="thead-scroll">
                        <Table.Row>
                          {fcResult.data && fcResult.data.length > 0
                            ? columns.map((column, index) => (
                              <Table.HeaderCell key={index}>{column}</Table.HeaderCell>
                            ))
                            : fcColumns.map((column, index) => (
                              <Table.HeaderCell key={index}>{column}</Table.HeaderCell>
                            ))}
                        </Table.Row>
                      </Table.Header>

                      <Table.Body className="tbody-scroll" style={{ height: 520 }}>
                        {result.length > 0 &&
                          result.map((r, index) => (
                            <Table.Row key={index}>
                              <Table.Cell>{index}</Table.Cell>
                              {r.length > 0 && (<Table.Cell>{r[0]}</Table.Cell>)}
                              {r.length > 1 && (<Table.Cell>{r[1]}</Table.Cell>)}
                              {r.length > 2 && (<Table.Cell>{r[2]}</Table.Cell>)}
                              {r.length > 3 && (<Table.Cell>{r[3]}</Table.Cell>)}
                              {r.length > 4 && (<Table.Cell>{r[4]}</Table.Cell>)}
                            </Table.Row>
                          ))}

                        <Table.Row key={result.length + 1}>
                          <Table.Cell>{t('total')}</Table.Cell>

                          {totalDemands.length > 0 &&
                            totalDemands.map((r) => (
                              <Table.Cell>{r.toFixed(2)}</Table.Cell>

                            ))}
                        </Table.Row>
                      </Table.Body>
                    </Table>
                    {fcResult && fcResult.data && fcResult.data.length > 0 && (
                      <ExcelFile
                        element={
                          <Button icon labelPosition="right" color="teal" className="w200">
                            {t('download')}
                            <Icon name="angle down" />
                          </Button>
                        }
                        filename={`DemandForecast_${moment(startDate).format('DD-MM-YYYY')}`}
                      >
                        <ExcelSheet dataSet={excelData} name={t('csvName')} />
                      </ExcelFile>
                    )}
                    {!(fcResult && fcResult.data && fcResult.data.length > 0) && (
                      <Button
                        icon
                        labelPosition="right"
                        color="teal"
                        className="w200"
                        disabled={!(fcResult && fcResult.length > 0)}
                      >
                        {t('download')}
                        <Icon name="angle down" />
                      </Button>
                    )}
                    <Button
                      disabled={forecast_result_id === -1}
                      icon
                      labelPosition="right"
                      color="blue"
                      className="w200"
                      onClick={this.sendEmail}
                    >
                      {t('sendMail')}
                      <Icon name="mail forward" />
                    </Button>
                  </Segment>
                </Grid.Column>
              </Grid>

            )
          }
        </Segment>
        <Footer />
        <Alert
          openModal={openModal}
          modalMessage={modalMessage}
          type={modalType}
          closeModal={this.closeModal}
          overrideHeader={overrideHeader}
        />

        <Modal basic size="mini" circular centered={true} open={forecast_loading}>
          <Image src='/animation.gif'
            width='60%'
            style={{
              borderRadius: '15em',
              margin: 'auto'
            }}
          />
          <p style={{
            position: 'relative',
            width: 550,
            marginLeft: i18n.language === 'en' ? -65 : 0,
            marginTop: 40,
            textAlign: 'left'
          }}>{t('demand.runHeader')}</p>

          <p style={{
            position: 'relative',
            width: 600,
            textAlign: 'left',
            marginLeft: i18n.language === 'en' ? -45 : -55
          }}>{t('demand.runBody')}</p>
        </Modal>

        <Notification
          ignore={this.state.ignore && this.state.title !== ''}
          notSupported={this.handleNotSupported.bind(this)}
          onPermissionGranted={this.handlePermissionGranted.bind(this)}
          onPermissionDenied={this.handlePermissionDenied.bind(this)}
          onShow={this.handleNotificationOnShow.bind(this)}
          onClick={this.handleNotificationOnClick.bind(this)}
          onClose={this.handleNotificationOnClose.bind(this)}
          onError={this.handleNotificationOnError.bind(this)}
          timeout={5000}
          title={this.state.title}
          options={this.state.options}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { id, resources } = state.user;
  return { id, resources };
};

export default withTranslation()(connect(mapStateToProps, {
  onChangeText,
  fetchUser,
  fetchResourceSettings,
  historicalDataCheck,
  historicalDataOutageCheck,
  fetchResult,
  sendForecastResultEmail,
  checkPageAuth
})(DemandFc));
