import axios from 'axios';
import { post as $post } from './core/fetch';
import config from './core/config';

export const check_page_auth = page => $post('/ui-check', { page });

// eslint-disable-next-line 
export default {
  authUser: (username, password) =>
    axios
      .post(`${config.authUrl}/login`, { username, password })
      .then(response => {
        if (response.data && response.data.status === true) {
          axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;
        }
        return response.data;
      })
      .catch(err => {
        throw err.message;
      }),
};
