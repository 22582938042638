import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Alert from './common/Alert';
import { messages } from '../constants';
import { Button, Segment, Grid, Dropdown, Input, Icon, Label, Message } from 'semantic-ui-react';
import { fetchPowerPlants, myPowerPlants, fetchMarketOrganizations, myMarketOrganizations, fetchKgup } from '../actions/market';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import ReactExport from 'react-export-excel';
import DataSheet from 'react-datasheet';
import { fetchUser } from '../actions/user';
import { checkPageAuth } from '../actions/auth';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class MarketDbTab extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      tabIndex: props.tabIndex,
      name: props.name,
      selected_power_plants: props.powerPlants,
      selected_organizations: props.myOrganizations,
      loading: true,
      auth: true,
      power_plants: [],
      organizations: props.organizations,
      kgupData: [],
      startDate: new Date(),
      excelData: [
        {
          columns: ['Time', 'Hour', 'Demand (mhw)', 'Outage (mhw)'],
          data: [],
        },
      ],
      openModal: false,
      modalMessage: '',
      modalType: '',
      overrideHeader: '',
    }
  }

  componentDidMount() {
    this.changeOrganization(this.state.selected_organizations);
  }

  changeOrganization = (value) => {
    this.setState({ loading: true })
    this.setState({ power_plants: [] })
    if (value.length === 0) {
      this.setState({ selected_organizations: value, loading: false })
    }
    else {
      this.props.fetchPowerPlants(value).then(data => {
        this.setState({
          power_plants: data.injectionUnitNames, selected_organizations: value, loading: false
        });
      })
    }

  }

  closeModal = () => {
    this.setState({ openModal: false, modalMessage: '' });
    if (this.state.changedPasword) {
      this.props.history.push('login');
    }
  };

  fetchMarketKgup = () => {
    const { selected_power_plants, startDate, power_plants } = this.state;
    const { t } = this.props;
    this.setState({ loading: true })
    const spp = power_plants.filter(x => selected_power_plants.includes(x.value));
    if (spp.length === 0) {
      this.setState({
        openModal: true,
        modalType: 'warning',
        modalMessage: t(messages.emptyPowerPlant),
        loading: false
      });
    }
    else {
      const kgupObject = {
        date: startDate,
        power_plants: spp
      }
      this.props.fetchKgup(kgupObject).then(data => {
        if (data.success) {
          const headerData = [{ value: t('hour'), readOnly: true }]
          const columns = [t('hour')]

          spp.forEach(s => {
            const pp = this.state.power_plants.filter(x => x.value === s.value)[0]
            if (pp !== undefined) {
              headerData.push({ value: pp.text, readOnly: true });
              columns.push(pp.text);
            }
          })
          const sheetData = [headerData].concat(data.result);
          this.setState({ loading: false, kgupData: sheetData, excelData: this.createExcelData(columns, data.excel_data) })
        }
        else {
          this.setState({
            loading: false,
            openModal: true,
            modalType: 'error',
            modalMessage: t(messages.marketDashboardKgupError),
          })
        }

      })
    }
  }

  createExcelData = (columns, data) => {
    return [{ columns, data }];
  };

  onChangeText = (text) => {
    this.setState({ name: text });
  }

  render() {
    const { id, name, auth, loading, selected_power_plants, power_plants, selected_organizations, organizations, openModal, modalType, modalMessage, overrideHeader, startDate, kgupData, excelData } = this.state;
    const { t } = this.props;
    return (
      <div className="wrapper" key={id}>
        <Segment loading={loading} style={{ width: '100%', minHeight: window.screen.height - 400 }}>

          {
            !auth && !loading && (
              <Message error>
                <Message.Header>{t('auth')}</Message.Header>
                <p>{t('authMessage')}</p>
              </Message>
            )
          }

          {
            auth && (
              <div>
                <div className="wp10" >
                  <Grid columns={1} divided>
                    <Grid.Column width={12}>{t('marketDb.tabName')}:</Grid.Column>
                  </Grid>
                </div>

                <div className="wp15">
                  <Input value={name} onChange={(e) => this.onChangeText(e.target.value)} />
                </div>

                <div className="wp5">
                  <Button
                    color="teal"
                    onClick={(e) => this.props.modifyTab(this.state)}
                    disabled={selected_organizations.length === 0}
                  >
                    {t('save')}
                  </Button>
                </div>

                <div className="h10" />

                <div className="wp10">
                  <Grid columns={1} divided>
                    <Grid.Column width={12}>{t('marketDb.myOrganizations')}:</Grid.Column>
                  </Grid>
                </div>
                <div className="wp90">
                  <Dropdown
                    placeholder={t('marketDb.organizations')}
                    fluid
                    multiple={true}
                    search
                    selection
                    value={selected_organizations}
                    options={organizations}
                    onChange={(e, { value }) => {
                      this.changeOrganization(value);
                    }}
                  />
                </div>

                <div className="h10" />

                <div className="wp10">
                  <Grid columns={2} divided>
                    <Grid.Column width={6}>{t('marketDb.myPlants')}:</Grid.Column>
                  </Grid>
                </div>
                <div className="wp90">
                  <Dropdown
                    multiple={true}
                    placeholder={t('marketDb.plants')}
                    fluid
                    search
                    selection
                    value={selected_power_plants}
                    options={power_plants}
                    onChange={(e, { value }) => {
                      this.setState({ selected_power_plants: value });
                    }}
                  />
                </div>

                <div className="h10" />

                <div className="wp10">{t('date')}:</div>
                <div className="wp15">
                  <DatePicker
                    selected={startDate}
                    onChange={date =>
                      this.setState({
                        startDate: date,
                      })
                    }
                    dateFormat="dd-MM-yyyy"
                    style={{ backgroundColor: 'green', width: '100%' }}
                    customInput={
                      <Input
                        className="wp100"
                        icon="calendar times"
                        iconPosition="left"
                        value={startDate}
                      />
                    }
                  />
                </div>
                <div className="wp70">
                  <Button
                    color="teal"
                    onClick={(e) => this.fetchMarketKgup()}
                  >
                    {t('marketDb.fetch')}
                  </Button>
                </div>

                <div className="h10" />
                <Label color='teal' ribbon='right' className='download-market' as='a'>
                  <ExcelFile
                    element={
                      <Button floated='right' size="small" icon labelPosition="right" color="teal" className="">
                        {t('download')}
                        <Icon name="angle down" />
                      </Button>
                    }
                    filename={`market-dashboard-${moment(startDate).format('DD-MM-YYYY')}`}
                  >
                    <ExcelSheet dataSet={excelData} name={moment(startDate).format('DD-MM-YYYY')} />
                  </ExcelFile>
                </Label>
                <DataSheet
                  className="market-excel"
                  data={kgupData}
                  attributesRenderer={(cell, i, j) => {
                    if (i === 0 && j === 0) return { "data-css": 'market-excel-header market-excel-first-column' };
                    if (j === 0) return { "data-first": 'market-excel-first-row' };
                    if (i === 0) return { "data-css": 'market-excel-header' };
                    if (i % 2 === 0) return { "data-css": "market-excel-even" };
                    else return { "data-css": "market-excel-odd" };
                  }}
                  valueRenderer={(cell, i) => cell.value}
                />
              </div>
            )
          }
        </Segment>
        <Alert
          openModal={openModal}
          modalMessage={modalMessage}
          type={modalType}
          closeModal={this.closeModal}
          overrideHeader={overrideHeader}
        />
      </div >
    );
  }
}


const mapStateToProps = state => {
  const { resources } = state.user;
  const accountId = state.user.id;
  return { resources, accountId };
};

export default withTranslation()(connect(mapStateToProps, {
  fetchPowerPlants,
  myPowerPlants,
  fetchMarketOrganizations,
  myMarketOrganizations,
  fetchKgup,
  fetchUser,
  checkPageAuth
})(MarketDbTab));

