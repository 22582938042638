import React from 'react';
import { Modal, Table, Button } from 'semantic-ui-react';
import { messages, errors } from '../../constants';
import { useTranslation } from 'react-i18next';

const Alert = ({ openModal, modalMessage, type, closeModal, overrideHeader }) => {
  const { t } = useTranslation();
  let color = '';
  let headerText = '';
  switch (type) {
    case 'success':
      color = 'green';
      headerText = t(messages.successModalHeader);
      break;
    case 'warning':
      color = 'yellow';
      headerText = t(messages.warningModalHeader);
      break;
    case 'error':
      color = 'red';
      headerText = t(errors.errorModalHeader);
      break;
    default:
      color = 'blue';
      headerText = t(messages.successModalHeader);
      break;
  }

  if (overrideHeader && overrideHeader !== "")
    headerText = overrideHeader

  return (
    <Modal size="small" dimmer="blurring" centered={false} open={openModal} onClose={closeModal}>
      <Table color={color}>
        <Table.Header></Table.Header>
      </Table>
      <Modal.Header>{headerText}</Modal.Header>
      <Modal.Content>
        <p>{modalMessage}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button color={color} onClick={closeModal}>
          {t('done')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
export default Alert;
