import React, { Component } from 'react';
import { connect } from 'react-redux';
import MonoHeader from './common/MonoHeader';
import Footer from './common/Footer';
import { Message, Segment } from 'semantic-ui-react';
import { checkPageAuth } from '../actions/auth';
import { withTranslation } from 'react-i18next';


class TurkeyFc extends Component {

  constructor(props) {
    super(props);
    this.state = {
      auth: false,
      loading: true
    }
  }

  componentDidMount() {
    this.props.checkPageAuth('turkeyfc').then(r => this.setState({ auth: r.result, loading: false }));
  }

  render() {
    const { loading, auth } = this.state;
    const { t } = this.props;
    return (
      <div className="wrapper">
        <MonoHeader history={this.props.history} activeItem="turkeyfc" />

        <div className="h20" />

        <Segment loading={loading} style={{ width: '100%', minHeight: window.screen.height - 400 }}>
          {
            !auth && !loading && (
              <Message error>
                <Message.Header>{t('auth')}</Message.Header>
                <p>{t('authMessage')}</p>
              </Message>
            )
          }
        </Segment>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default withTranslation()(connect(mapStateToProps, {
  checkPageAuth
})(TurkeyFc));