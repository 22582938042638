import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Header, Segment } from 'semantic-ui-react';
import MonoHeader from './common/MonoHeader';
import Footer from './common/Footer';
import { checkPageAuth } from '../actions/auth';


class TermsAndConditions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auth: false
        }
    }

    componentDidMount() {
        this.props.checkPageAuth('demandfc').then(d => this.setState({ auth: true }));
    }

    render() {
        const { t } = this.props;

        return (
            <div className="wrapper">
                <MonoHeader queryButton history={this.props.history} />

                <div className="h20" />
                <Segment loading={!this.state.auth} style={{ width: '100%', minHeight: window.screen.height - 400 }}>
                    <Header as='h2'>{t('terms.terms')}</Header>
                    <p>
                        {t('terms.p1')}
                    </p>

                    <p>
                        {t('terms.p2')}
                    </p>

                    <p>
                        {t('terms.p3')}
                    </p>

                    <Header as='h3'>1. {t('terms.responsibility')}</Header>
                    <p>
                        a. {t('terms.r1')}
                    </p>
                    <p>

                        b. {t('terms.r2')}
                    </p>
                    <p>
                        c. {t('terms.r3')}
                    </p>
                    <p>
                        d. {t('terms.r4')}
                    </p>

                    <Header as='h3'>2. {t('terms.ipr')}</Header>
                    <p>
                        2.1. {t('terms.i1')}
                    </p>
                    <p>

                        2.2. {t('terms.i2')}
                    </p>

                    <Header as='h3'>3. {t('terms.confident')}</Header>
                    <p>
                        3.1. {t('terms.c1')}
                    </p>
                    <p>

                        3.2. {t('terms.c2')}
                    </p>

                    <p>

                        3.3. {t('terms.c3')}
                    </p>

                    <Header as='h3'>4. {t('terms.guarantee')}</Header>
                    <p>
                        {t('terms.g1')}
                    </p>

                    <Header as='h3'>5. {t('terms.reg')}</Header>
                    <p>
                        {t('terms.reg1')}
                    </p>

                    <Header as='h3'>6. {t('terms.forceM')}</Header>
                    <p>
                        {t('terms.f1')}
                    </p>

                    <Header as='h3'>7. {t('terms.aia')}</Header>
                    <p>
                        {t('terms.aia1')}
                    </p>

                    <Header as='h3'>8. {t('terms.ac')}</Header>
                    <p>
                        {t('terms.ac1')}
                    </p>

                    <Header as='h3'>9. {t('terms.notify')}</Header>
                    <p>
                        {t('terms.n1')}
                    </p>

                    <Header as='h3'>10. {t('terms.ea')}</Header>
                    <p>
                        {t('terms.ea1')}
                    </p>

                    <Header as='h3'>11. {t('terms.clj')}</Header>
                    <p>
                        {t('terms.clj1')}
                    </p>
                </Segment>
                <div className="h20" />
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

export default withTranslation()(connect(mapStateToProps, { checkPageAuth })(TermsAndConditions));
