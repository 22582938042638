import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Accordion, Segment } from 'semantic-ui-react';
import MonoHeader from './common/MonoHeader';
import Footer from './common/Footer';
import { checkPageAuth } from '../actions/auth';


class ReadMe extends Component {

  constructor(props) {
    super(props);
    this.state = {
      auth: false
    }
  }

  componentDidMount() {
    this.props.checkPageAuth('demandfc').then(d => this.setState({ auth: true }));
  }

  render() {
    const { t } = this.props;

    const demandPanels = [
      { key: 'panel-1a', title: t('readme.panel1ATitle'), content: t('readme.panel1AContent') },
      { key: 'panel-1b', title: t('readme.panel1BTitle'), content: t('readme.panel1BContent') },
      { key: 'panel-1c', title: t('readme.panel1CTitle'), content: t('readme.panel1CContent') },
      { key: 'panel-1d', title: t('readme.panel1DTitle'), content: t('readme.panel1DContent') },
      { key: 'panel-1e', title: t('readme.panel1ETitle'), content: t('readme.panel1EContent') }
    ]

    const demandContent = (
      <Accordion.Accordion panels={demandPanels} />
    )

    const myDataContent = (
      <div></div>
    )

    const turkeyForecastContent = (
      <div></div>
    )

    const powerPlantContent = (
      <div></div>
    )

    const marketDashboardPanels = [
      { key: 'panel-5a', title: t('readme.panel5ATitle'), content: t('readme.panel5AContent') },
    ]

    const marketDashboardContent = (
      <Accordion.Accordion panels={marketDashboardPanels} />
    )

    const computerRootPanels = [
      { key: 'panel-1', title: 'Forecast', content: { content: demandContent } },
      { key: 'panel-2', title: 'My Data', content: { content: myDataContent } },
      { key: 'panel-3', title: t('nationWideForecast'), content: { content: turkeyForecastContent } },
      { key: 'panel-4', title: 'My Power Plant', content: { content: powerPlantContent } },
      { key: 'panel-5', title: 'Market Dashboard', content: { content: marketDashboardContent } },
    ]

    return (
      <div className="wrapper">
        <MonoHeader queryButton activeItem="readme" history={this.props.history} />

        <div className="h20" />

        <Segment loading={!this.state.auth} style={{ width: '100%', minHeight: window.screen.height - 400 }}>
          <Accordion panels={computerRootPanels} fluid styled />
        </Segment>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default withTranslation()(connect(mapStateToProps, { checkPageAuth })(ReadMe));
