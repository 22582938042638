import { check_page_auth } from '../api/auth';
import { errors } from '../constants';
export const checkPageAuth = (page) => dispatch =>
    new Promise(function (resolve, reject) {
        check_page_auth(page).then(data => {
            if (data.success) {
                resolve(data);
            } else {
                reject(errors.authCheck);
            }
        });
    });