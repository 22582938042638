import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Segment, Button, Icon, Tab, Table, Message, Checkbox, Dropdown } from 'semantic-ui-react';
import { LocalStorage } from '../helpers/storage';
import MonoHeader from './common/MonoHeader';
import Footer from './common/Footer';
import Alert from './common/Alert';
import { messages, errors, validation } from '../constants';
import { fetchSettingsData, fetchResourceSettings, saveSettingsData, saveResourceSettingsData, changePassword } from '../actions/settings';
import { getTabs } from '../actions/market';
import { fetchUser } from '../actions/user';
import { checkPageAuth } from '../actions/auth';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { setMinutes, setHours } from 'date-fns'
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';


class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      delimiter: '',
      run_forecast_auth: false,
      same_day_update_auth: false,
      damOfferLimit: 0,
      emailList: [],
      powerPlants: [],
      currentPwd: '',
      newPwd1: '',
      newPwd2: '',
      tab1Error: '',
      tab1Success: '',
      tab2Error: '',
      tab2Success: '',
      validationErrors: {},
      openModal: false,
      modalMessage: '',
      modalType: '',
      changedPasword: false,
      missingDayHour: new Date(),
      runForecasterHour: new Date(),
      runAutoForecast: true,
      allowSameDayUpdate: false,
      accountId: 0,
      forecastDateRange: 0,
      allowSameDayHourRestrict: true,
      runMarketDb: false,
      dashboardTabs: [],
      allTabs: [],
      dashboardRunTime: new Date(),
      dashboardEmails: [],
      lang: "",
      lowerLimit: 0,
      upperLimit: 0,
      resources: [],
      resourceId: 0
    };

    this.renderTab1 = this.renderTab1.bind(this);
    this.renderTab2 = this.renderTab2.bind(this);
    this.renderTab3 = this.renderTab3.bind(this);
    this.saveSettings = this.saveSettings.bind(this);
    this.changePwd = this.changePwd.bind(this);
  }

  closeModal = () => {
    this.setState({ openModal: false, modalMessage: '' });
    if (this.state.changedPasword) {
      this.props.history.push('login');
    }
  };

  componentDidMount() {

    this.props.checkPageAuth('samedayupdate').then(r => this.setState({ same_day_update_auth: r.result }));
    this.props.checkPageAuth('autorunforecast').then(r => this.setState({ run_forecast_auth: r.result }));
    this.props.checkPageAuth('marketdb').then(r => this.setState({ runMarketDb: r.result }));

    this.props.fetchUser().then(data => {
      if (data.success && data.account && data.account.id) {
        this.setState({
          resources: data.account.resources?.map(r => ({
            key: r.id,
            text: r.name,
            value: r.id,
          }))
        });
        this.props.fetchSettingsData(data.account.id).then(result => {
          if (result.success) {
            this.setState({
              loading: false,
              delimiter: result.delimiter,
              damOfferLimit: result.dam_offer_limit,
              powerPlants: result.power_plants && result.power_plants.length > 0 ? result.power_plants.split(',') : [],
              accountId: result.account_id,
              dashboardTabs: result.dashboard_tabs && result.dashboard_tabs.length > 0 ? result.dashboard_tabs.split(',') : [],
              dashboardRunTime: moment(new Date().toISOString().split('T').shift() + ' ' + result.dashboard_run_time).toDate(),
              dashboardEmails: result.dashboard_emails && result.dashboard_emails.length > 0 ? result.dashboard_emails.split(',') : [],
              resourceId: data.account.resources[0].id
            });
            this.getResourceSettings(data.account.resources[0].id);
          } else {
            this.setState({
              delimiter: '',
              damOfferLimit: '',
              emailList: [],
              powerPlants: []
            });
          }
        });
      }
    });

    this.props.getTabs().then(d => {
      const userTabs = []
      if (d.tabs) {
        d.tabs.forEach(t => {
          userTabs.push({
            key: t.id.toString(),
            value: t.id.toString(),
            text: t.name
          });
        });
        this.setState({ allTabs: userTabs });
      }
    });
  }

  onChange(prop, value) {
    this.setState({ tab1Success: '', tab1Error: '', tab2Success: '', tab2Error: '', tab3Success: '', tab3Error: '' });
    this.setState({ [prop]: value });
  }

  validate = (tab, data) => {
    let errors = {};
    const { t } = this.props;
    console.log(tab, data)
    switch (tab) {
      case 'tab1':
        if (data.resourceId === 0) errors.resourceId = t(validation.blankValidation)
        break;
      case 'tab2':
        if (!data.currentPwd) errors.currentPwd = t(validation.blankValidation);
        if (!data.newPwd1) errors.newPwd1 = t(validation.blankValidation);
        if (!data.newPwd2) errors.newPwd2 = t(validation.blankValidation);
        break;
      default:
        errors = {};
    }

    return errors;
  };

  saveSettings = () => {
    const { accountId, powerPlants, dashboardEmails, dashboardRunTime, dashboardTabs } = this.state;

    const { t } = this.props;
    this.setState({ loading: true });
    this.props
      .saveSettingsData(accountId, powerPlants.join(), dashboardEmails.join(),
        moment(dashboardRunTime).format("HH:mm"), dashboardTabs.join())
      .then(result => {
        if (result.success) {
          this.setState({
            openModal: true,
            loading: false,
            modalType: 'success',
            modalMessage: t(messages.settingsSaveSuccess),
          });
        } else {
          this.setState({
            openModal: true,
            loading: false,
            modalType: 'error',
            modalMessage: t(errors.settingsSaveError),
          });
        }
      });
  };

  saveResourceSettings = () => {
    const { resourceId, emailList, missingDayHour, runForecasterHour, runAutoForecast, lang,
      upperLimit, lowerLimit, allowSameDayUpdate, forecastDateRange, allowSameDayHourRestrict } = this.state;

    const { t } = this.props;
    const validationErrors = this.validate('tab1', { resourceId, emailList });
    this.setState({ validationErrors, loading: true });

    if (Object.keys(validationErrors).length === 0) {
      this.props
        .saveResourceSettingsData(resourceId, emailList.join(),
          runAutoForecast, moment(runForecasterHour).format("HH:mm"),
          moment(missingDayHour).format("HH:mm"),
          allowSameDayUpdate, forecastDateRange, allowSameDayHourRestrict,
          lang, lowerLimit, upperLimit)
        .then(result => {
          if (result.success) {
            this.setState({
              openModal: true,
              loading: false,
              modalType: 'success',
              modalMessage: t(messages.settingsSaveSuccess),
            });
          } else {
            this.setState({
              openModal: true,
              loading: false,
              modalType: 'error',
              modalMessage: t(errors.settingsSaveError),
            });
          }
        });
    }
    else {
      this.setState({
        loading: false,
        openModal: true,
        modalType: 'warning',
        modalMessage: t(validation.resourceCheck),
      });
    }
  };

  changePwd = () => {
    const { currentPwd, newPwd1, newPwd2 } = this.state;
    const { t } = this.props;
    const validationErrors = this.validate('tab2', { currentPwd, newPwd1, newPwd2 });
    this.setState({ validationErrors });

    if (Object.keys(validationErrors).length === 0) {
      if (newPwd1 !== newPwd2) {
        this.setState({ tab2Error: t(validation.pwdControl) });
      } else {
        this.props.changePassword(currentPwd, newPwd1).then(result => {
          if (result.success) {
            window.$$token = '';
            LocalStorage.set('token', '');
            LocalStorage.set('user_external_id', '');
            LocalStorage.set('account_access_token', '');
            this.setState({
              openModal: true,
              modalType: 'success',
              modalMessage: t(messages.changePwdSuccess),
              changedPasword: true,
            });

            // this.props.history.push('login');
          } else {
            this.setState({
              openModal: true,
              modalType: 'error',
              modalMessage: result.message,
            });
          }
        });
      }
    }
  };

  getResourceSettings(resourceId) {
    this.setState({ loading: true });
    this.props.fetchResourceSettings(resourceId).then(result => {
      if (result.success) {
        this.setState({
          loading: false,
          delimiter: result.delimiter,
          damOfferLimit: result.dam_offer_limit,
          emailList: result.emails && result.emails.length > 0 ? result.emails.split(',') : [],
          runAutoForecast: result.auto_run,
          runForecasterHour: moment(new Date().toISOString().split('T').shift() + ' ' + result.auto_run_time).toDate(),
          missingDayHour: moment(new Date().toISOString().split('T').shift() + ' ' + result.missing_day_run_time).toDate(),
          allowSameDayUpdate: result.allow_same_day_update,
          forecastDateRange: result.forecast_date_range,
          allowSameDayHourRestrict: result.allow_same_day_hour_restrict,
          lang: result.lang,
          lowerLimit: result.lower_limit,
          upperLimit: result.upper_limit
        });
      } else {
        this.setState({
          delimiter: '',
          damOfferLimit: '',
          emailList: [],
          powerPlants: []
        });
      }
    });
  }

  renderTab1() {
    const { same_day_update_auth, loading, emailList, lowerLimit, upperLimit } = this.state;
    const { t, i18n } = this.props;
    return (
      <Tab.Pane key="options" attached={false} style={{ minHeight: 510 }}>
        <Segment raised style={{ minHeight: 480 }} loading={loading} textAlign="left">
          <Form>
            <Form.Field>
              <label>{t('resource')}</label>
              <Dropdown
                placeholder={t('resource')}
                fluid
                search
                selection
                value={this.state.resourceId}
                options={this.state.resources}
                onChange={(e, { value }) => {
                  this.setState({ resourceId: value });
                  this.getResourceSettings(value);
                }}
              />
            </Form.Field>
            <Form.Field>
              <label>{t('lowerLimit')}</label>
              <Form.Input
                fluid
                placeholder={t('lowerLimit')}
                value={lowerLimit}
                onChange={e => this.onChange('lowerLimit', e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <label>{t('upperLimit')}</label>
              <Form.Input
                fluid
                placeholder={t('upperLimit')}
                value={upperLimit}
                onChange={e => this.onChange('upperLimit', e.target.value)}
              />
            </Form.Field>
            <div className="h20" />
            <Form.Field>
              <label>{t('lng')}</label>
              <p>
                <Checkbox
                  radio
                  label={t('lng-tr')}
                  name='checkboxRadioGroup'
                  value={"tr"}
                  checked={this.state.lang === "tr"}
                  onChange={e => { this.setState({ lang: "tr" }); i18n.changeLanguage('tr'); }}
                />

                <Checkbox
                  radio
                  label={t('lng-en')}
                  name='checkboxRadioGroup'
                  value={"en"}
                  checked={this.state.lang === "en"}
                  onChange={e => { this.setState({ lang: "en" }); i18n.changeLanguage('en') }}
                />
              </p>
            </Form.Field>
            <div className="h20" />

            <Form.Field>
              <label>{t('settings.defaultForecastScope')}</label>


              <Checkbox
                radio
                label={t('settings.one')}
                name='checkboxRadioGroup'
                value={true}
                checked={this.state.forecastDateRange === 1}
                onChange={e => this.setState({ forecastDateRange: 1 })}
              />

              <Checkbox
                radio
                label={t('settings.two')}
                name='checkboxRadioGroup'
                value={false}
                checked={this.state.forecastDateRange === 2}
                onChange={e => this.setState({ forecastDateRange: 2 })}
              />

              <Checkbox
                radio
                label={t('settings.three')}
                name='checkboxRadioGroup'
                value={false}
                checked={this.state.forecastDateRange === 3}
                onChange={e => this.setState({ forecastDateRange: 3 })}
              />

              <Checkbox
                radio
                label={t('settings.four')}
                name='checkboxRadioGroup'
                value={false}
                checked={this.state.forecastDateRange === 4}
                onChange={e => this.setState({ forecastDateRange: 4 })}
              />

            </Form.Field>
            <div className="h20" />
            <Form.Field>
              <label>{t('settings.intradayDataUpdate')}</label>

              {
                !same_day_update_auth && (
                  <Message error style={{ display: 'block' }}>
                    <Message.Header>{t('auth')}</Message.Header>
                    <p>{t('authMessage')}</p>
                  </Message>
                )
              }
              {
                same_day_update_auth && (
                  <div>

                    <Checkbox
                      radio
                      label={t('open')}
                      name='checkboxRadioGroup'
                      value={true}
                      checked={this.state.allowSameDayUpdate === true}
                      onChange={e => this.setState({ allowSameDayUpdate: true })}
                    />

                    <Checkbox
                      radio
                      label={t('close')}
                      name='checkboxRadioGroup'
                      value={false}
                      checked={this.state.allowSameDayUpdate === false}
                      onChange={e => this.setState({ allowSameDayUpdate: false })}
                    />
                  </div>

                )
              }
            </Form.Field>

            <div className="h40" />

            <Form.Field>
              <label>{t('settings.intradayHourlyCheck')}</label>
              {
                !same_day_update_auth && (
                  <Message error style={{ display: 'block' }}>
                    <Message.Header>{t('auth')}</Message.Header>
                    <p>{t('authMessage')}</p>
                  </Message>
                )
              }

              {
                same_day_update_auth && !this.state.allowSameDayHourRestrict && (
                  <Message warning style={{ display: 'block' }}>
                    <Message.Header>{t('warning')}</Message.Header>
                    <p>{t('settings.hourlyCheckWarningText')}</p>
                  </Message>
                )
              }
              {
                same_day_update_auth && (
                  <div>

                    <Checkbox
                      radio
                      label={t('open')}
                      name='checkboxRadioGroup'
                      value={true}
                      checked={this.state.allowSameDayHourRestrict === true}
                      onChange={e => this.setState({ allowSameDayHourRestrict: true })}
                    />

                    <Checkbox
                      radio
                      label={t('close')}
                      name='checkboxRadioGroup'
                      value={false}
                      checked={this.state.allowSameDayHourRestrict === false}
                      onChange={e => this.setState({ allowSameDayHourRestrict: false })}
                    />
                  </div>

                )
              }
            </Form.Field>

            <div className="h40" />

            <Form.Field>
              <label>{t('settings.emailList')}</label>
              <Table color="red">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Email</Table.HeaderCell>
                    <Table.HeaderCell>
                      <Button
                        floated="right"
                        icon
                        labelPosition="left"
                        primary
                        size="small"
                        onClick={() => {
                          if (emailList.findIndex(x => x === '') === -1) {
                            emailList.push('');
                            this.setState({ emailList });
                          }
                        }}
                      >
                        <Icon name="add" /> {t('new')}
                      </Button>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {emailList &&
                    emailList.map((email, index) => (
                      <Table.Row>
                        <Table.Cell>
                          <Form.Input
                            id={index.toString()}
                            key={index}
                            fluid
                            placeholder="Email"
                            type="email"
                            value={email}
                            onChange={e => {
                              emailList[index] = e.target.value;
                              this.setState({ emailList });
                            }}
                          />
                        </Table.Cell>
                        <Table.Cell textAlign="right" collapsing>
                          <span
                            onClick={() => {
                              emailList.splice(index, 1);
                              this.setState({ emailList });
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <Icon name="trash alternate" color="red" />
                          </span>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </Form.Field>
            <Button
              name="saveSettings"
              type="submit"
              color="blue"
              size="large"
              onClick={() => this.saveResourceSettings()}
            >
              {t('save')}
            </Button>
          </Form>
        </Segment>
      </Tab.Pane >
    );
  }

  renderTab2() {
    const { currentPwd, newPwd1, newPwd2, tab2Error, validationErrors } = this.state;
    const { t } = this.props;
    return (
      <Tab.Pane key="changePwd" attached={false} style={{ minHeight: 510 }}>
        <Segment raised style={{ minHeight: 480 }} textAlign="left">
          <Form>
            <Form.Field>
              <label>{t('settings.currentPwd')}</label>
              <Form.Input
                fluid
                placeholder="********"
                type="password"
                value={currentPwd}
                onChange={e => this.onChange('currentPwd', e.target.value)}
                error={validationErrors.currentPwd}
              />
            </Form.Field>
            <Form.Field>
              <label>{t('settings.newPwd')}</label>
              <Form.Input
                fluid
                placeholder="********"
                type="password"
                value={newPwd1}
                onChange={e => this.onChange('newPwd1', e.target.value)}
                error={validationErrors.newPwd1}
              />
            </Form.Field>
            <Form.Field>
              <label>{t('settings.confirmPwd')}</label>
              <Form.Input
                fluid
                placeholder="********"
                type="password"
                value={newPwd2}
                onChange={e => this.onChange('newPwd2', e.target.value)}
                error={validationErrors.newPwd2}
              />
            </Form.Field>
            {tab2Error && (
              <Message negative>
                <Message.Header> {t('error')} </Message.Header>
                <p>{tab2Error}</p>
              </Message>
            )}
            <Button
              name="changePwd"
              type="submit"
              color="blue"
              size="large"
              onClick={() => this.changePwd()}
            >
              {t('save')}
            </Button>
          </Form>
        </Segment>
      </Tab.Pane>
    );
  }

  renderTab3() {
    const { run_forecast_auth } = this.state;
    const { t } = this.props;

    return (
      <Tab.Pane key="autoWork" attached={false} style={{ minHeight: 510 }}>
        <Segment raised style={{ minHeight: 480 }} textAlign="left">

          {
            !run_forecast_auth && (
              <Message error>
                <Message.Header>{t('auth')}</Message.Header>
                <p>{t('authMessage')}</p>
              </Message>
            )
          }

          {
            run_forecast_auth && (
              <Form>
                <p>
                  {t('settings.runAuto')}
                </p>

                <p>
                  <Checkbox
                    radio
                    label={t('yes')}
                    name='checkboxRadioGroup'
                    value={true}
                    checked={this.state.runAutoForecast === true}
                    onChange={e => this.setState({ runAutoForecast: true })}
                  />

                  <Checkbox
                    radio
                    label={t('no')}
                    name='checkboxRadioGroup'
                    value={false}
                    checked={this.state.runAutoForecast === false}
                    onChange={e => this.setState({ runAutoForecast: false })}
                  />
                </p>

                <Button
                  name="changeScheduler"
                  type="submit"
                  color="blue"
                  size="large"
                  onClick={() => this.saveResourceSettings()}
                >
                  {t('save')}
                </Button>
              </Form>

            )
          }
        </Segment>
      </Tab.Pane>
    );
  }

  renderTab4() {
    const { allTabs, dashboardTabs, dashboardEmails, runMarketDb } = this.state;
    const { t } = this.props;
    return (
      <Tab.Pane key="marketDashboard" attached={false} style={{ minHeight: 510 }}>
        <Segment raised style={{ minHeight: 480 }} textAlign="left">

          {
            !runMarketDb && (
              <Message error>
                <Message.Header>{t('auth')}</Message.Header>
                <p>{t('authMessage')}</p>
              </Message>
            )
          }

          {
            runMarketDb && (
              <Form>
                <p>{t('settings.myTabs')}</p>
                <p>
                  <Dropdown
                    multiple={true}
                    placeholder={t('marketDb.tabs')}
                    fluid
                    search
                    selection
                    value={dashboardTabs}
                    options={allTabs}
                    onChange={(e, { value }) => {
                      this.setState({ dashboardTabs: value });
                    }}
                  />
                </p>

                <Form.Field>
                  <label>{t('settings.emailList')}</label>
                  <Table color="red">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>
                          <Button
                            floated="right"
                            icon
                            labelPosition="left"
                            primary
                            size="small"
                            onClick={() => {
                              if (dashboardEmails.findIndex(x => x === '') === -1) {
                                dashboardEmails.push('');
                                this.setState({ dashboardEmails });
                              }
                            }}
                          >
                            <Icon name="add" /> {t('new')}
                          </Button>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {dashboardEmails &&
                        dashboardEmails.map((email, index) => (
                          <Table.Row>
                            <Table.Cell>
                              <Form.Input
                                id={index}
                                key={index}
                                fluid
                                placeholder="Email"
                                type="email"
                                value={email}
                                onChange={e => {
                                  dashboardEmails[index] = e.target.value;
                                  this.setState({ dashboardEmails });
                                }}
                              />
                            </Table.Cell>
                            <Table.Cell textAlign="right" collapsing>
                              <span
                                onClick={() => {
                                  dashboardEmails.splice(index, 1);
                                  this.setState({ dashboardEmails });
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                <Icon name="trash alternate" color="red" />
                              </span>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                    </Table.Body>
                  </Table>
                </Form.Field>


                <Button
                  name="changeScheduler"
                  type="submit"
                  color="blue"
                  size="large"
                  onClick={() => this.saveSettings()}
                >
                  {t('save')}
                </Button>
              </Form>

            )
          }
        </Segment>
      </Tab.Pane>
    );
  }

  render() {
    const { openModal, modalType, modalMessage } = this.state;
    const { t } = this.props;
    const panes = [
      { menuItem: t('settings.options'), render: () => this.renderTab1(), key: "options" },
      { menuItem: t('settings.changePwd'), render: () => this.renderTab2(), key: "changePwd" },
      { menuItem: 'Market Dashboard', render: () => this.renderTab4(), key: "dashboard" },
    ];

    return (
      <div className="settings-wrapper">
        <MonoHeader activeItem="settings" history={this.props.history} />

        <div className="h20" />

        <Segment
          textAlign="center"
          style={{ width: '100%', minHeight: window.screen.height - 400 }}
        >
          <div className="settings-form">
            <Tab panes={panes} menu={{ pointing: true }} />
          </div>
        </Segment>
        <Footer />
        <Alert
          openModal={openModal}
          modalMessage={modalMessage}
          type={modalType}
          closeModal={this.closeModal}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const accountId = state.user.id;
  return { accountId };
};

export default withTranslation()(connect(mapStateToProps, {
  fetchUser,
  fetchSettingsData,
  fetchResourceSettings,
  saveSettingsData,
  saveResourceSettingsData,
  changePassword,
  checkPageAuth,
  getTabs
})(Settings));
