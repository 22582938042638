
export const errors = {
  errorModalHeader: "errorModalHeader",
  default: "default",
  auth: "authError",
  authCheck: "authCheck",
  user: "user",
  historicalDataUploadError: "historicalDataUploadError",
  outageDataUploadError: "outageDataUploadError",
  settingsSaveError: "settingsSaveError",
  powerPlantsSaveError: "powerPlantsSaveError",
  marketOrganizationSaveError: "marketOrganizationSaveError",
  changePwdError: "changePwdError",
  historicalDataCheckError: "historicalDataCheckError",
  historicalDataLoadError: "historicalDataLoadError",
  updateDataError: "updateDataError",
  runForecastErrorTitle: "runForecastErrorTitle",
  runForecastErrorMessage: "runForecastErrorMessage"

};

export const validation = {
  blankValidation: "blankValidation",
  emptyValidation: "emptyValidation",
  pwdControl: "pwdControl",
  resourceCheck: "resourceCheck"
};

export const messages = {
  successModalHeader: "successModalHeader",
  warningModalHeader: "warningModalHeader",
  historicalDataUploadSuccess: "historicalDataUploadSuccess",
  outageDataUploadSuccess: "outageDataUploadSuccess",
  alreadyRun: "alreadyRun",
  settingsSaveSuccess: "settingsSaveSuccess",
  powerPlantsSaveSuccess: "powerPlantsSaveSuccess",
  emptyPowerPlant: "emptyPowerPlant",
  marketOrganizationsSaveSuccess: "marketOrganizationsSaveSuccess",
  marketDashboardKgupError: "marketDashboardKgupError",
  changePwdSuccess: "changePwdSuccess",
  updateDataSuccess: "updateDataSuccess",
  viewDataEmpty: "viewDataEmpty",
  marketTabSaveSuccess: "marketTabSaveSuccess",
  marketTabMaxCount: "marketTabMaxCount",
  marketTabSaveError: "marketTabSaveError",
  clipboardFormatError: "clipboardFormatError",
  lastDayCheck: "lastDayCheck"
};
