import { post as $post } from './core/fetch';
import config from './core/config';

export const uploadHistoricalData = file => $post('/upload-historical', file);
export const uploadOutageData = file => $post('/upload-outage', file);
export const historical_data_check = resource_id =>
  $post('/historical-data-check', { resource_id });
export const historical_data_outage_check = resource_id =>
  $post('/historical-data-outage-check', { resource_id });
export const send_forecast_result_email = (account_id, forecast_result_id) =>
  $post('/send-forecast-emails', { account_id, forecast_result_id });
export const runForecast = (account_id, forecast_start_date, resource_id, pipeline_type, forecast_days) =>
  $post(
    `${config.mlUrl}/run-forecaster`,
    {
      pipeline_type: pipeline_type,
      forecast_days: forecast_days,
      account_id,
      forecast_start_date,
      resource_id,
    },
    { external: true },
  );
export const remove_cache = (prefix) =>
  $post(
    `${config.mlUrl}/remove-cache`,
    { prefix },
    { external: true },
  );
