import {
    fetch_power_plants, save_power_plants, my_power_plants,
    fetch_market_organizations, save_market_organizations, my_market_organizations,
    get_tabs, add_tab, update_tab, delete_tab,
    fetch_kgup
} from '../api/market';

export const fetchPowerPlants = (organizationId) => dispatch =>
    new Promise(function (resolve, reject) {
        fetch_power_plants(organizationId)
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });

export const savePowerPlants = (power_plants) => dispatch =>
    new Promise(function (resolve, reject) {
        save_power_plants(power_plants)
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });

export const myPowerPlants = () => dispatch =>
    new Promise(function (resolve, reject) {
        my_power_plants()
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });

export const fetchMarketOrganizations = () => dispatch =>
    new Promise(function (resolve, reject) {
        fetch_market_organizations()
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });

export const saveMarketOrganizations = (organizations) => dispatch =>
    new Promise(function (resolve, reject) {
        save_market_organizations(organizations)
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });

export const myMarketOrganizations = (tabId) => dispatch =>
    new Promise(function (resolve, reject) {
        my_market_organizations(tabId)
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });


export const fetchKgup = (kgup) => dispatch =>
    new Promise(function (resolve, reject) {
        fetch_kgup(kgup)
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });


export const getTabs = () => dispatch =>
    new Promise(function (resolve, reject) {
        get_tabs()
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });

export const addTab = (tab) => dispatch =>
    new Promise(function (resolve, reject) {
        add_tab(tab)
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });

export const updateTab = (tab) => dispatch =>
    new Promise(function (resolve, reject) {
        update_tab(tab)
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });


export const deleteTab = (tab) => dispatch =>
    new Promise(function (resolve, reject) {
        delete_tab(tab)
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });