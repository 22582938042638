import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import MonoHeader from './common/MonoHeader';
import Footer from './common/Footer';
import Alert from './common/Alert';
import { messages } from '../constants';
import { Label, Segment, Tab, Message, Icon, Menu, Button, Modal } from 'semantic-ui-react';
import { fetchMarketOrganizations, getTabs, addTab, updateTab, deleteTab } from '../actions/market';
import { checkPageAuth } from '../actions/auth';
import MarketDbTab from './MarketDbTab';
import { isMobile } from 'react-device-detect';


class MarketDb extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      auth: false,
      panes: [],
      tabsIndex: 0,
      openModal: false,
      modalMessage: '',
      modalType: '',
      overrideHeader: '',
      allOrganization: [],
      openDelete: false,
      selectedTab: -1
    }
  }

  componentDidMount() {
    this.props.checkPageAuth('marketdb').then(r => this.setState({ auth: r.result }));
    this.props.fetchMarketOrganizations().then(data => {
      this.props.getTabs().then(d => {
        const tabs = [];
        let tabCount = 0;
        if (d.success) {
          d.tabs.forEach((t, index) => {
            const tab = {
              id: t.id,
              isNew: false,
              tabIndex: index,
              menuItem: (
                <Menu.Item key={index}>
                  {t.name} <Icon style={{ paddingLeft: 10 }} name='remove' onClick={() => this.deleteTab(index)} />
                </Menu.Item>
              ),
              name: t.name,
              myOrganizations: t.organizations.length > 0 ? t.organizations.split(',') : [],
              powerPlants: t.power_plants.length > 0 ? t.power_plants.split(',') : []
            }
            tab.render = () => this.renderTab(tab)
            tabs.push(tab);
            tabCount++;
          });
        }
        this.setState({ loading: false, allOrganization: data.organizations, panes: tabs, tabsIndex: tabCount })
      })
    });

  }

  closeModal = () => {
    this.setState({ openModal: false, modalMessage: '' });
    if (this.state.changedPasword) {
      this.props.history.push('login');
    }
  };

  closeDeleteModal = () => {
    this.setState({ openDelete: false, loading: false });
  }

  addNewTab() {
    const { panes, tabsIndex } = this.state;
    const { t } = this.props;

    if (panes.length === 10) {
      this.setState({
        openModal: true,
        modalType: 'warning',
        modalMessage: t(messages.marketTabMaxCount),
      });
    }
    else {
      let index = tabsIndex + 1;
      let tabPanes = panes;
      const tab = {
        id: 0,
        tabIndex: index,
        menuItem: (
          <Menu.Item key={index}>
            {t('marketDb.newTab')} - {index} <Icon style={{ paddingLeft: 10 }} name='remove' onClick={() => this.deleteTab(index)} />
          </Menu.Item>
        ),
        name: t('marketDb.newTab') + " - " + index,
        myOrganizations: [],
        powerPlants: []

      }
      tab.render = () => this.renderTab(tab)
      tabPanes.push(tab);
      this.setState({ panes: tabPanes, tabsIndex: index });
    }

  }

  removeTab = () => {
    const tab = this.state.selectedTab;
    const removedTab = this.state.panes.filter(f => f.tabIndex === tab)[0];
    if (removedTab.isNew === false) {
      this.props.deleteTab({ id: removedTab.id }).then(d => {
        if (d.success) {
          this.setState({ panes: this.state.panes.filter(f => f.tabIndex !== tab), loading: false, openDelete: false });
        }

      })
    }
    else {
      this.setState({ panes: this.state.panes.filter(f => f.tabIndex !== tab), loading: false, openDelete: false });
    }
  }

  deleteTab(tab) {
    this.setState({ loading: true, selectedTab: tab, openDelete: true });
  }

  updateNewTab = (tab) => {
    this.setState({ loading: true });
    const { t } = this.props;
    const { id, name, tabIndex, selected_organizations, selected_power_plants, power_plants } = tab;
    const spp = power_plants.filter(x => selected_power_plants.includes(x.value)).map(x => x.value);
    const tabObject = {
      name: name,
      organizations: selected_organizations.length > 0 ? selected_organizations.join() : "",
      power_plants: spp.length > 0 ? spp.join() : ""
    }

    if (id === 0) {
      this.props.addTab(tabObject).then(d => {
        if (d.success) {

          let updateTab = this.state.panes.find(f => f.tabIndex === tabIndex);
          updateTab.id = d.tab_id;
          updateTab.isNew = false;
          updateTab.menuItem = this.changeTabName(name, tabIndex);
          updateTab.name = name;
          updateTab.tabIndex = tabIndex;
          updateTab.selected_organizations = selected_organizations;
          updateTab.selected_power_plants = spp;
          updateTab.myOrganizations = selected_organizations;
          updateTab.powerPlants = spp;

          this.setState({
            loading: false,
            openModal: true,
            modalType: 'success',
            modalMessage: t(messages.marketTabSaveSuccess),
            panes: this.state.panes
          });

        }
        else {
          this.setState({
            loading: false,
            openModal: true,
            modalType: 'error',
            modalMessage: t(messages.marketTabSaveError),
          });

        }
      });
    }
    else {
      tabObject.id = id;
      this.props.updateTab(tabObject).then(d => {
        if (d.success) {

          let updateTab = this.state.panes.find(f => f.tabIndex === tabIndex);
          updateTab.isNew = false;
          updateTab.menuItem = this.changeTabName(name, tabIndex);
          updateTab.name = name;
          updateTab.tabIndex = tabIndex;
          updateTab.selected_organizations = selected_organizations;
          updateTab.selected_power_plants = spp;
          updateTab.myOrganizations = selected_organizations;
          updateTab.powerPlants = spp;
          this.setState({
            loading: false,
            openModal: true,
            modalType: 'success',
            modalMessage: t(messages.marketTabSaveSuccess),
            panes: this.state.panes
          });

        }
        else {
          this.setState({
            loading: false,
            openModal: true,
            modalType: 'error',
            modalMessage: t(messages.marketTabSaveError),
          });
        }
      });
    }
  }

  changeTabName = (name, index) => {
    return (
      <Menu.Item key={index}>
        {name} <Icon style={{ paddingLeft: 10 }} name='remove' onClick={() => this.deleteTab(index)} />
      </Menu.Item>
    )
  }

  renderTab(tab) {
    return (
      <MarketDbTab
        key={tab.tabIndex} id={tab.id} name={tab.name}
        modifyTab={this.updateNewTab} tabIndex={tab.tabIndex}
        myOrganizations={tab.myOrganizations} powerPlants={tab.powerPlants}
        organizations={this.state.allOrganization} />
    )
  }
  render() {

    const { auth, loading, panes, openModal, modalMessage, modalType, overrideHeader, openDelete } = this.state;
    const { t } = this.props;

    return (
      <div className="wrapper" >
        <MonoHeader history={this.props.history} activeItem="marketdb" />

        <div className="h20" />

        <Segment loading={loading} style={{ width: '100%', minHeight: window.screen.height - 400 }}>

          {
            !auth && !loading && (
              <Message error>
                <Message.Header>{t('auth')}</Message.Header>
                <p>{t('authMessage')}</p>
              </Message>
            )
          }

          {
            auth && !loading && (
              <div>
                {!isMobile && (
                  <Label ribbon color='teal' style={{ cursor: 'pointer' }} onClick={() => this.addNewTab()}>
                    <Icon name="add" /> {t('new')}
                  </Label>
                )}

                <Tab
                  menu={{ tabular: true, attached: true, className: "responsive-tab" }}
                  panes={panes}
                />

              </div>
            )
          }

        </Segment>
        <Footer />
        <Alert
          openModal={openModal}
          modalMessage={modalMessage}
          type={modalType}
          closeModal={this.closeModal}
          overrideHeader={overrideHeader}
        />
        <Modal
          open={openDelete}
          onClose={this.closeDeleteModal}
        >
          <Modal.Header>Delete Tab</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete your tab?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.closeDeleteModal} negative>
              No
            </Button>
            <Button
              onClick={this.removeTab}
              positive
              labelPosition='right'
              icon='checkmark'
              content='Yes'
            />
          </Modal.Actions>
        </Modal>
      </div >
    );
  }
}


const mapStateToProps = state => {
  const { resources } = state.user;
  const accountId = state.user.id;
  return { resources, accountId };
};

export default withTranslation()(connect(mapStateToProps, {
  checkPageAuth, fetchMarketOrganizations,
  addTab, getTabs, updateTab, deleteTab
})(MarketDb));

