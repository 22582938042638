import React, { Component } from 'react';
import { Header, Form, Segment, Button, Image, Message } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { onChangeText, authUser, fetchUser } from '../actions/user';
import { LocalStorage } from '../helpers/storage';
import { isMobile } from 'react-device-detect';
import { withTranslation } from 'react-i18next';


class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      pageError: '',
      lock: false
    };

    this.auth = this.auth.bind(this);

  }

  componentDidMount() {
    if (this.props.cookies.get('token')) this.props.history.push('/');
  }

  componentWillUnmount() {
    this.setState({ pageError: '', loading: false });
  }

  auth = () => {
    const { username, password } = this.props;
    this.setState({ loading: true });

    this.props
      .authUser(username, password)
      .then(response => {
        if (response.access_token) {
          window.$$token = response.access_token;
          LocalStorage.set('token', response.access_token);
          LocalStorage.set('user_external_id', response.user_external_id);
          this.props
            .fetchUser()
            .then(data => {
              LocalStorage.set('account_access_token', data.account_access_token);
              if (isMobile) {
                this.props.history.push('marketdb');

              }
              else {
                this.props.history.push('demandfc');

              }
            })
            .catch(error => {
              this.setState({ pageError: error, loading: false });
            });
        }
        if (response.lock) {
          this.setState({ lock: true, loading: false });
          return;
        }
        if (response.status === false && response.message) {
          //todo ozlem: buraya log ekle, mesaji degistir.
          this.setState({ pageError: response.message, loading: false });
        }
      })
      .catch(err => {
        this.setState({ pageError: err, loading: false });
      });
  };

  render() {
    const { error, t } = this.props;
    const { loading, lock } = this.state;

    return (
      <div className="login-wrapper">
        <div className="login-form">
          <Header as="h1" textAlign="center" className="background-black ">
            <Image src="/electron-logo-white.png" style={{ maxHeight: 70 }} />
          </Header>
          <Form size="small" id="login-form">
            <Segment loading={loading}>
              <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                placeholder="User Name"
                onChange={e => this.props.onChangeText({ prop: 'username', value: e.target.value })}
              />
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="********"
                type="password"
                onChange={e => this.props.onChangeText({ prop: 'password', value: e.target.value })}
              />

              <Button
                color="black"
                fluid
                size="large"
                onClick={e => {
                  this.auth();
                }}
              >
                {t('loginBtn')}
              </Button>
            </Segment>
          </Form>

          {lock && (
            <Message negative>
              <Message.Header> {t('error')} </Message.Header>
              <p>{t('blockUser')}</p>
            </Message>
          )}

          {error && !lock && (
            <Message negative>
              <Message.Header> {t('error')} </Message.Header>
              <p>{t(error)}</p>
            </Message>
          )}

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { username, password, userInfo, error, fetched } = state.user;

  return { username, password, userInfo, error, fetched, cookies: ownProps.cookies };
};

export default withTranslation()(withCookies(
  connect(mapStateToProps, {
    onChangeText,
    authUser,
    fetchUser,
  })(Login)),
);
