import React, { Component } from 'react';
import MonoHeader from './common/MonoHeader';
import Footer from './common/Footer';
import { Message, Segment } from 'semantic-ui-react';

class NotFound extends Component {
  render() {
    const loading = false;
    return (
      <div className="wrapper">
        <MonoHeader history={this.props.history} />

        <div className="h20" />

        <Segment loading={loading} style={{ width: '100%', minHeight: window.screen.height - 400 }}>
          <Message error>
            <Message.Header>Bilgilendirme!</Message.Header>
            <p>Böyle bir sayfa bulunamadı.</p>
          </Message>
        </Segment>

        <Footer />
      </div>
    );
  }
}

export default NotFound;
