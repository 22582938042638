import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import MonoHeader from './common/MonoHeader';
import Footer from './common/Footer';
import { Message, Segment, Tab } from 'semantic-ui-react';
import Alert from './common/Alert';
import { checkPageAuth } from '../actions/auth';


class ApiUi extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
            modalMessage: '',
            modalType: '',
            overrideHeader: '',
            loading: false,
            auth: false
        }

        this.ownApiRender = this.ownApiRender.bind(this);
        this.electronApiRender = this.electronApiRender.bind(this);
    }

    componentDidMount() {
        this.props.checkPageAuth('demandfc').then(d => this.setState({ auth: true }));
    }

    ownApiRender = () => {
        const { t } = this.props;
        return (
            <Message error>
                <Message.Header>{t('auth')}</Message.Header>
                <p>{t('authMessage')}</p>
            </Message >
        )
    }

    electronApiRender = () => {
        const { t } = this.props;
        return (
            <Segment>
                <Message>
                    <Message.Header>{t('api.overview')}</Message.Header>
                    <Message.List>
                        <Message.Item>{t('api.generalMessage')}</Message.Item>
                        <Message.Item><b>{t('api.version')}</b> : 1.0.0</Message.Item>
                    </Message.List>

                    <div className="h20" />

                    <Message.Header>URI {t('api.schema')}</Message.Header>
                    <Message.List>
                        <Message.Item><b>{t('api.server')}</b> : electron.api.monoanalytics.com</Message.Item>
                        <Message.Item><b>{t('api.root')}</b> : /</Message.Item>
                        <Message.Item><b>{t('api.schemas')}</b> : HTTPS</Message.Item>
                    </Message.List>

                    <div className="h20" />

                    <Message.Header>{t('api.userGuide')}</Message.Header>
                    <Message.List>
                        <Message.Item>{t('api.guideStep1')}</Message.Item>
                        <Message.Item>{t('api.guideStep2')}</Message.Item>
                    </Message.List>

                    <div className="h20" />

                    <Message.Header>{t('api.getTokenInfo')}</Message.Header>
                    <Message.List>
                        <Message.Item><b>Url</b> : https://electron.api.monoanalytics.com/login</Message.Item>
                        <Message.Item><b>Method</b> : POST /login</Message.Item>
                        <Message.Item><b>{t('api.params')}</b> : {'{ "username": "USERNAME", "password": "PASSWORD" }'}</Message.Item>
                        <Message.Item><b>{t('api.response')}</b> : {'{"message": "Logged in as USERNAME", "success": true, "access_token": "ACCESS_TOKEN"}'}</Message.Item>
                    </Message.List>

                    <div className="h20" />

                    <Message.Header>Data Import</Message.Header>
                    <Message.List>
                        <Message.Item><b>Url</b> : https://electron.api.monoanalytics.com/import</Message.Item>
                        <Message.Item><b>Method</b> : POST /import</Message.Item>
                        <Message.Item><b>Header</b> : {t('api.importHeader')}</Message.Item>
                        <Message.Item><b>{t('api.params')}</b> : {'{"data": [{ "outage": 10, "demand": 531, "date": "2020-05-08 20:00:00" }, { "outage": 11,"demand": 495,"date": "2020-05-08 21:00:00" }] }'}</Message.Item>
                        <Message.Item><b>Test</b> : {t('api.importTest')}</Message.Item>
                        <Message.Item><b>Run Forecast</b> : {t('api.importRunForecast')}</Message.Item>
                        <Message.Item><b>Run Forecast {t('api.params')}</b> : {t('api.importRunForecastParam')}</Message.Item>
                        <Message.Item><b>{t('api.sampleRequest')}</b> :  <a href="/api-example.json" target="_blank">{t('api.sampleFile')}</a></Message.Item>
                        <Message.Item><b>{t('api.response')}</b> : {'{"success": true, "message": "Import has been succeeded."}'}</Message.Item>
                    </Message.List>

                    <div className="h20" />

                    <Message.Header>{t('api.testDataControl')}</Message.Header>
                    <Message.List>
                        <Message.Item><b>Url</b> : https://electron.api.monoanalytics.com/test-data</Message.Item>
                        <Message.Item><b>Method</b> : GET /test-data</Message.Item>
                        <Message.Item><b>Header</b> : {t('api.testDataHeader')}</Message.Item>
                        <Message.Item><b>{t('api.response')}</b> : {t('api.testDataResult')}</Message.Item>
                    </Message.List>

                    <div className="h20" />

                    <Message.Header>Get Forecast Result</Message.Header>
                    <Message.List>
                        <Message.Item><b>Url</b> : https://electron.api.monoanalytics.com/get-forecast-result</Message.Item>
                        <Message.Item><b>Method</b> : POST /get-forecast-result</Message.Item>
                        <Message.Item><b>Header</b> : {t('api.getResultHeader')}</Message.Item>
                        <Message.Item><b>{t('api.params')}</b> : {'{  "forecast_start_date": "2020-05-08 17:00:00", "forecast_days": 2}'}</Message.Item>
                        <Message.Item><b>{t('api.response')}</b> : {t('api.getResultBody')}</Message.Item>
                    </Message.List>
                </Message>
            </Segment>
        )
    }

    render() {
        const { auth, openModal, modalMessage, modalType, overrideHeader } = this.state;
        const panes = [
            { menuItem: 'Electron Api', render: () => this.electronApiRender() },
            { menuItem: 'Custom Api', render: () => this.ownApiRender() }
        ];
        return (
            <div className="wrapper">
                <MonoHeader history={this.props.history} activeItem="api" />

                <Segment loading={!auth} style={{ width: '100%', minHeight: window.screen.height - 400 }}>
                    <Tab panes={panes} menu={{ pointing: true }} />

                    {!this.state.auth && <Message error>
                        <Message.Header>Yetki!</Message.Header>
                        <p>Bu modülü kullanmak için yetkiniz bulunmamaktadır.</p>
                    </Message>}
                </Segment>
                <Footer />
                <Alert
                    openModal={openModal}
                    overrideHeader={overrideHeader}
                    modalMessage={modalMessage}
                    type={modalType}
                    closeModal={this.closeModal}
                />
            </div >
        );
    }
}

const mapStateToProps = state => {
    const { id, resources } = state.user;
    return { id, resources };
};

export default withTranslation()(connect(mapStateToProps, {
    checkPageAuth
})(ApiUi));