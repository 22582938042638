import {
  USER_CHANGE_TEXT,
  AUTH_USER_SUCCESS,
  AUTH_USER_ERROR,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  CLEAR_USER_DATA,
} from '../constants/actionTypes';
import { LocalStorage } from '../helpers/storage';
import auth from '../api/auth';
import { account_identity } from '../api/user';
import { errors } from '../constants';

export const onChangeText = ({ prop, value }) => ({
  type: USER_CHANGE_TEXT,
  payload: { prop, value },
});

export const authUserSuccess = user => ({
  type: AUTH_USER_SUCCESS,
  payload: user,
});

export const authUserError = error => ({
  type: AUTH_USER_ERROR,
  payload: error,
});

export const fetchUserSuccess = (user, account_access_token) => ({
  type: FETCH_USER_SUCCESS,
  payload: { user, account_access_token },
});

export const fetchUserError = error => ({
  type: FETCH_USER_ERROR,
  payload: error,
});

export const clearUserData = () => ({
  type: CLEAR_USER_DATA,
});

export const authUser = (username, password) => dispatch =>
  new Promise(function (resolve, reject) {
    auth
      .authUser(username, password)
      .then(data => {
        if (data.status === true) {
          dispatch(authUserSuccess(data));
          resolve(data);
        }
        else if (data.lock === true) {
          dispatch(authUserSuccess(data));
          resolve(data);
        }
        else {
          dispatch(authUserError(errors.auth));
          reject(errors.auth);
        }
      })
      .catch(err => {
        dispatch(authUserError(errors.auth));
        reject(errors.auth);
      });
  });

export const fetchUser = () => dispatch =>
  new Promise(function (resolve, reject) {
    account_identity(LocalStorage.get('user_external_id')).then(data => {
      if (data.success) {
        dispatch(fetchUserSuccess(data.account, data.account_access_token));
        resolve(data);
      } else {
        dispatch(fetchUserError(errors.user));
        reject(errors.user);
      }
    });
  });
