import { FC_CHANGE_TEXT } from '../constants/actionTypes';

const INITIAL_STATE = {
  error: '',
};

// eslint-disable-next-line
export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case FC_CHANGE_TEXT: {
      return { ...state, [action.payload.prop]: action.payload.value };
    }

    default:
      return state;
  }
};
