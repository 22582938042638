/* eslint-disable import/no-anonymous-default-export */
import {
  USER_CHANGE_TEXT,
  AUTH_USER_SUCCESS,
  AUTH_USER_ERROR,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  CLEAR_USER_DATA,
} from '../constants/actionTypes';

const INITIAL_STATE = {
  is_active: false,
  created_at: '',
  resources: [],
  name: '',
  id: 0,
  deleted_at: '',
  account_access_token: '',
  token: '',

  error: '',
  fetched: false,
  authenticated: false,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case USER_CHANGE_TEXT: {
      return { ...state, [action.payload.prop]: action.payload.value };
    }

    case CLEAR_USER_DATA: {
      return { ...state, error: '', fetched: false };
    }

    case AUTH_USER_SUCCESS: {
      return { ...state, token: action.payload.token, authenticated: true };
    }

    case AUTH_USER_ERROR: {
      return { ...state, token: '', authenticated: false, error: action.payload };
    }

    case FETCH_USER_SUCCESS: {
      return {
        ...action.payload.user,
        account_access_token: action.payload.account_access_token,
        fetched: true,
      };
    }

    case FETCH_USER_ERROR: {
      return { ...state, fetched: false, error: action.payload };
    }

    default:
      return state;
  }
};
