import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import { Image, Menu, Grid, Icon } from 'semantic-ui-react';
import { LocalStorage } from '../../helpers/storage';

class MonoHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeItem: 'demandfc',
    };

    this.handleItemClick = this.handleItemClick.bind(this);
  }

  handleItemClick = activeItem => {
    this.setState({ activeItem });
    this.props.history.push(activeItem);
  };

  componentDidMount() {
    this.setState({ activeItem: this.props.activeItem });
  }

  logOut() {
    LocalStorage.set('token', '');
    LocalStorage.set('user_external_id', '');
    LocalStorage.set('account_access_token', '');
    this.props.history.push('login');
  }

  render() {
    const { activeItem } = this.state;
    const { t } = this.props;
    return (
      <div className="header-container">


        <Grid verticalAlign="middle" columns={3}>
          <Grid.Column width={2}>
            <Image size="tiny" src="/electron-logo-white.png" style={{ marginLeft: 20 }} />
          </Grid.Column>
          <Grid.Column width={10}>
            <Menu inverted pointing secondary>
              <Menu.Item
                name="demandfc"
                active={activeItem === 'demandfc'}
                onClick={() => this.handleItemClick('demandfc')}
              >
                Forecast
              </Menu.Item>
              <Menu.Item
                name="mydata"
                active={activeItem === 'mydata'}
                onClick={() => this.handleItemClick('mydata')}
              >
                My Data
              </Menu.Item>
              <Menu.Item
                name="turkeyfc"
                active={activeItem === 'turkeyfc'}
                onClick={() => this.handleItemClick('turkeyfc')}
              >
                {t('nationWideForecast')}
              </Menu.Item>
              <Menu.Item
                name="powerplant"
                active={activeItem === 'powerplant'}
                onClick={() => this.handleItemClick('powerplant')}
              >
                My Power Plant
              </Menu.Item>
              <Menu.Item
                name="marketdb"
                active={activeItem === 'marketdb'}
                onClick={() => this.handleItemClick('marketdb')}
              >
                Market Dashboard
              </Menu.Item>
            </Menu>
          </Grid.Column>
          <Grid.Column width={4}>
            <Menu inverted pointing secondary>
              <Menu.Item
                position="right"
                name="api"
                active={activeItem === 'api'}
                onClick={() => this.handleItemClick('api')}
              >
                <Icon name="code" inverted />
                Api
              </Menu.Item>

              <Menu.Item
                position="right"
                name="settings"
                active={activeItem === 'settings'}
                onClick={() => this.handleItemClick('settings')}
              >
                Settings
              </Menu.Item>

              <Menu.Item
                position="right"
                name="readme"
                active={activeItem === 'readme'}
                onClick={() => this.handleItemClick('readme')}
              >
                Read Me
              </Menu.Item>
              <Menu.Item position="right" name="signOut" onClick={() => this.logOut()} icon="sign-out">
                <Icon name="sign-out" inverted />
                Sign Out
              </Menu.Item>
            </Menu>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default withTranslation()(withCookies(connect(mapStateToProps, {})(MonoHeader)));
