import React from 'react';
import { Route, Router, Redirect, Switch } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import { isAuthenticated } from '../helpers/authentication';

import Login from '../components/Login';
import ReadMe from '../components/ReadMe';
import Settings from '../components/Settings';
import DemandFc from '../components/DemandFc';
import TurkeyFc from '../components/TurkeyFc';
import PowerPlant from '../components/PowerPlant';
import MarketDb from '../components/MarketDb';
import MyData from '../components/MyData';
import TermsAndConditions from '../components/TermsAndConditions';
import PrivacyPolicy from '../components/PrivacyPolicy';
import ApiUi from '../components/ApiUi';
import NotFound from '../components/NotFound';

const history = createHistory();

const router = (
  <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
    <div className="content-wrapper">
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/" exact component={DemandFc} />
        <Route path="/mydata" exact component={MyData} />
        <Route path="/demandfc" exact component={DemandFc} />
        <Route path="/turkeyfc" exact component={TurkeyFc} />
        <Route path="/powerplant" exact component={PowerPlant} />
        <Route path="/marketdb" exact component={MarketDb} />
        <Route path="/readme" exact component={ReadMe} />
        <Route path="/settings" exact component={Settings} />
        <Route path="/terms-and-conditions" exact component={TermsAndConditions} />
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
        <Route path="/api" exact component={ApiUi} />

        {!isAuthenticated() && <Redirect push to="/login" />}
        <Route component={NotFound} />

      </Switch>
    </div>

  </Router>
);

export { router };
