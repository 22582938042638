import { post as $post } from './core/fetch';
import axios from 'axios';

import config from './core/config';
import { LocalStorage } from '../helpers/storage';

export const fetchSettings = account_id => $post('/fetch-settings', { account_id });
export const fetchResourceSetting = resource_id => $post('/fetch-resource-settings', { resource_id });
export const saveSettings = (account_id, power_plants, dashboard_emails, dashboard_run_time, dashboard_tabs) =>
  $post('/save-settings', {
    account_id, power_plants, dashboard_emails, dashboard_run_time, dashboard_tabs
  });

export const saveResourceSettings = (resource_id, emails, auto_run, auto_run_time,
  missing_day_run_time, allow_same_day_update,
  forecast_date_range, allow_same_day_hour_restrict,
  lang, lower_limit, upper_limit) =>
  $post('/save-resource-settings', {
    resource_id, emails, auto_run, auto_run_time,
    missing_day_run_time, allow_same_day_update,
    forecast_date_range, allow_same_day_hour_restrict,
    lang, lower_limit, upper_limit
  });


export const changePwd = (current_pwd, new_pwd) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${LocalStorage.get('token')}`;
  return axios
    .post(`${config.authUrl}/change-password`, { current_pwd, new_pwd })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      throw err.message;
    });
};
