import { get as $get, post as $post, put as $put, remove as $delete } from './core/fetch';

export const fetch_power_plants = (organizationId) => $get(`/power-plants/${organizationId}`);
export const save_power_plants = (power_plants) => $post('/save-power-plants', { power_plants });
export const my_power_plants = () => $get('/my-power-plants');

export const fetch_market_organizations = () => $get('/market-organizations');
export const save_market_organizations = (dashboard_organizations) => $post('/save-market-organizations', { dashboard_organizations });
export const my_market_organizations = (tabId) => $get(`/my-market-organizations/${tabId}`);

export const fetch_kgup = (kgup) => $post('/market-kgup', kgup);

export const get_tabs = () => $get('/market-dashboard-tabs');
export const add_tab = (tab) => $post('/market-dashboard-tabs', tab);
export const update_tab = (tab) => $put('/market-dashboard-tabs', tab);
export const delete_tab = (tab) => $delete('/market-dashboard-tabs', tab);

