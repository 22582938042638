import { get_historical_data, update_historical_data } from '../api/historical';
import { errors } from '../constants';

export const getHistoricalData = (resource_id, start_date, end_date) => dispatch =>
    new Promise(function (resolve, reject) {
        get_historical_data(resource_id, start_date, end_date).then(data => {
            if (data.success) {
                resolve(data);
            } else {
                reject(errors.historicalDataLoadError);
            }
        });
    });


export const updateHistoricalData = (data) => dispatch =>
    new Promise(function (resolve, reject) {
        update_historical_data(data).then(data => {
            if (data.success || data.missing) {
                resolve(data);
            } else {
                reject(errors.historicalDataLoadError);
            }
        });
    });