import { FC_CHANGE_TEXT } from '../constants/actionTypes';
import {
  historical_data_check,
  historical_data_outage_check,
  runForecast,
  send_forecast_result_email,
  remove_cache
} from '../api/forecast';

export const onChangeText = ({ prop, value }) => ({
  type: FC_CHANGE_TEXT,
  payload: { prop, value },
});


export const historicalDataCheck = resourceId => dispatch =>
  new Promise(function (resolve, reject) {
    historical_data_check(resourceId)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });

export const historicalDataOutageCheck = resourceId => dispatch =>
  new Promise(function (resolve, reject) {
    historical_data_outage_check(resourceId)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });

export const fetchResult = (accountId, startDate, resourceId, pipeline_type, forecast_days) => dispatch =>
  new Promise(function (resolve, reject) {
    runForecast(accountId, startDate, resourceId, pipeline_type, forecast_days)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });

export const sendForecastResultEmail = (account_id, forecast_result_id) => dispatch =>
  new Promise(function (resolve, reject) {
    send_forecast_result_email(account_id, forecast_result_id)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });

export const removeCache = (prefix) => dispatch =>
  new Promise(function (resolve, reject) {
    remove_cache(prefix)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });