import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Header, Segment } from 'semantic-ui-react';
import MonoHeader from './common/MonoHeader';
import Footer from './common/Footer';
import { checkPageAuth } from '../actions/auth';


class PrivacyPolicy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            auth: false
        }
    }

    componentDidMount() {
        this.props.checkPageAuth('demandfc').then(d => this.setState({ auth: true }));
    }

    render() {
        const { t } = this.props;

        return (
            <div className="wrapper">
                <MonoHeader queryButton history={this.props.history} />

                <div className="h20" />
                <Segment loading={!this.state.auth} style={{ width: '100%', minHeight: window.screen.height - 400 }}>
                    <Header as='h2'>{t('privacy.header')}</Header>
                    <p>
                        {t('privacy.p1')}
                    </p>
                    <Header as='h3'>{t('privacy.h1')}</Header>
                    <p>
                        {t('privacy.p2')}
                    </p>

                    <p>
                        {t('privacy.p3')}
                    </p>

                    <p>
                        {t('privacy.p4')}
                    </p>
                    <p>

                        {t('privacy.p5')}
                    </p>
                    <p>
                        {t('privacy.p6')}
                    </p>
                    <p>
                        {t('privacy.p7')}
                    </p>

                    <p>
                        {t('privacy.p8')}
                    </p>

                    <Header as='h3'>{t('privacy.h2')}</Header>
                    <p>
                        {t('privacy.p9')}
                    </p>
                    <p>
                        {t('privacy.p10')}
                    </p>

                    <p>
                        {t('privacy.p11')}
                    </p>

                    <p>
                        {t('privacy.p12')}
                    </p>

                    <p>
                        {t('privacy.p13')}
                    </p>

                    <p>
                        {t('privacy.p14')}
                    </p>

                </Segment>
                <div className="h20" />
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

export default withTranslation()(connect(mapStateToProps, { checkPageAuth })(PrivacyPolicy));
