import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './routing/configureStore';
import { router } from './routing/router';
import createHistory from 'history/createBrowserHistory';
import { CookiesProvider } from 'react-cookie';

import 'semantic-ui-css/semantic.min.css';
import 'react-datasheet/lib/react-datasheet.css';
import './i18n';

const history = createHistory();
const store = configureStore(undefined, history);
ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>{router}</Provider>
  </CookiesProvider>,
  document.getElementById('root'),
);
