import { fetchSettings, fetchResourceSetting, saveSettings, saveResourceSettings, changePwd } from '../api/settings';

export const fetchSettingsData = accountId => dispatch =>
  new Promise(function (resolve, reject) {
    fetchSettings(accountId)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });

export const fetchResourceSettings = resourceId => dispatch =>
  new Promise(function (resolve, reject) {
    fetchResourceSetting(resourceId)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });

export const saveSettingsData = (accountId, power_plants, dashboardEmails,
  dashboardRunTime, dashboardTabs) => dispatch =>
    new Promise(function (resolve, reject) {
      saveSettings(accountId, power_plants, dashboardEmails,
        dashboardRunTime, dashboardTabs)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });

export const saveResourceSettingsData = (resourceId, emails, auto_run,
  auto_run_time, missing_day_run_time, allowSameDayUpdate,
  forecastDateRange, allowSameDayHourRestrict, lang, lowerLimit, upperLimit) => dispatch =>
    new Promise(function (resolve, reject) {
      saveResourceSettings(resourceId, emails, auto_run,
        auto_run_time, missing_day_run_time, allowSameDayUpdate,
        forecastDateRange, allowSameDayHourRestrict, lang, lowerLimit, upperLimit)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });

export const changePassword = (currentPwd, newPwd) => dispatch =>
  new Promise(function (resolve, reject) {
    changePwd(currentPwd, newPwd)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
