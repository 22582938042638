import React from 'react';
import { Grid, List, Container, Image } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';


const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="header-container">
      <Grid columns={3} verticalAlign="middle" >
        <Grid.Column width={1} style={{ marginRight: 20 }}>
          <Image size="tiny" src="electron-logo-white.png" style={{ marginLeft: 20 }} />
        </Grid.Column>
        <Grid.Column width={6}>
          <p style={{ color: 'white' }}>©2020 Created By Mono - {t('allRightReserved')}</p>
        </Grid.Column>
        <Grid.Column width={8}>
          <Container textAlign="right">
            <List horizontal inverted divided link size="small">
              <List.Item as="a" href="https://monoanalytics.com/contact" target="blank">
                {t('contactUs')}
              </List.Item>
              <List.Item as="a" href='/terms-and-conditions'>
                {t('termsOfUse')}
              </List.Item>
              <List.Item as="a" href='privacy-policy'>
                {t('privacyPolicy')}
              </List.Item>
            </List>
          </Container>
        </Grid.Column>
      </Grid>
    </div>
  );
};
export default Footer;
