import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import MonoHeader from './common/MonoHeader';
import Footer from './common/Footer';
import { Message, Segment, Grid, Input, Button, Table, Icon, Dropdown, Label } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ReactExport from 'react-export-excel';
import DataSheet from 'react-datasheet';
import Alert from './common/Alert';
import { messages } from '../constants';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';



import {
    getHistoricalData, updateHistoricalData
} from '../actions/historical';
import { fetchUser } from '../actions/user';
import { historicalDataCheck, removeCache } from '../actions/forecast';
import { checkPageAuth } from '../actions/auth';
import { fetchResourceSettings } from '../actions/settings';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class MyData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
            modalMessage: '',
            modalType: '',
            overrideHeader: '',
            resourceId: 0,
            loading: true,
            auth: false,
            viewStarDate: moment().startOf('month').toDate(),
            viewEndDate: moment(new Date()).subtract(1, 'days').toDate(),
            myData: [],
            uploadData: [],
            excelData: [
                {
                    columns: ['Time', 'Hour', 'Demand (mhw)', 'Outage (mhw)'],
                    data: [],
                },
            ],
            uploadStartDate: moment(new Date()).subtract(1, 'days').toDate(),
            uploadEndDate: moment(new Date()).subtract(1, 'days').toDate(),
            lastDay: '',
            allowSameDayUpdate: false,
            updateProcess: false,
            allowSameDayHourRestrict: true,
            lowerLimit: 0,
            upperLimit: 0
        }
    }

    closeModal = () => {
        this.setState({ openModal: false, modalMessage: '', overrideHeader: '' });
        if (this.state.changedPasword) {
            this.props.history.push('login');
        }
    };

    componentDidMount() {

        this.props.checkPageAuth('mydata').then(r => {
            this.setState({ auth: r.result });

            if (r.result) {
                this.props.fetchUser().then(data => {
                    this.getResourceSettings(data.account.resources[0].id);
                    this.checkHistoricalData(data.account.resources[0].id);
                })
                const excelData = [
                    [
                        { value: 'Date', readOnly: true },
                        { value: 'Hour', readOnly: true },
                        { value: 'Demand (MWh)', readOnly: true },
                        { value: 'Outage (MWh)', readOnly: true }
                    ]

                ]

                for (let i = 0; i < 24; i++) {
                    excelData.push([
                        { value: '' },
                        { value: '' },
                        { value: '' },
                        { value: '' }
                    ])
                }
                this.setState({ uploadData: excelData })
            }
            else {
                this.setState({ loading: false });
            }

        });


    }

    searchData = (source) => {
        this.setState({ loading: true });
        const { t } = this.props;
        const columns = this.state.excelData[0].columns;
        const { resourceId, viewStarDate, viewEndDate, uploadStartDate, uploadEndDate } = this.state;
        const startDate = moment(source === "VIEW" ? viewStarDate : uploadStartDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
        const endDate = moment(source === "VIEW" ? viewEndDate : uploadEndDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');
        const sheetData = []
        this.props.getHistoricalData(resourceId, startDate, endDate).then(data => {
            if (data.success) {
                const myData = []
                if (source === "VIEW" && data.historicals.length === 0) {
                    this.setState({
                        loading: false,
                        openModal: true,
                        modalType: 'warning',
                        modalMessage: t(messages.viewDataEmpty),
                    });
                }
                data.historicals.forEach(x => {
                    if (source === "VIEW") {
                        myData.push({
                            outage: x.outage,
                            demand: x.demand,
                            time: moment(x.time).format('DD-MM-YYYY'),
                            hour: moment(x.time).format('HH:mm:ss')
                        })
                    }
                    else if (source === "UPDATE") {
                        sheetData.push(
                            [{ readOnly: true, value: moment(x.time).format('DD-MM-YYYY') }, { readOnly: true, value: moment(x.time).format('HH:mm:ss') }, { value: x.demand }, { value: x.outage }]
                        )
                    }

                })
                if (source === "VIEW")
                    this.setState({ loading: false, myData: myData, excelData: this.createExcelData(columns, myData) })
                else if (source === "UPDATE") {
                    this.createUpdateData(sheetData, data.last_day);
                }
            }
            else {
                this.setState({
                    loading: false,
                    openModal: true,
                    modalType: 'error',
                    modalMessage: t(messages.historicalDataLoadError),
                });
            }
        })
    }

    updateData = () => {
        this.setState({ loading: true, updateProcess: true });
        const { uploadData, resourceId, allowSameDayHourRestrict, lowerLimit, upperLimit } = this.state;
        const { t } = this.props;
        const uploadedData = []
        let error = false
        let missingData = false
        let limitError = false;
        uploadData.forEach(x => {
            if (x[2].hint === "not-valid" || x[3].hint === "not-valid") {
                error = true;
            }

            if (parseFloat(x[2].value) < lowerLimit || (upperLimit !== 0 && parseFloat(x[2].value) > upperLimit)) {
                limitError = true;
            }
            if (allowSameDayHourRestrict) {
                if (x[2].readOnly && x[3].value === '') {
                    missingData = true

                }
                else if (!x[2].readOnly && (x[2].value === '' || x[3].value === '')) {
                    missingData = true
                }
            }


            if (x[0].updated !== undefined && x[2].value !== '') {
                uploadedData.push({
                    outage: x[3].value,
                    demand: x[2].value,
                    time: x[0].value,
                    hour: x[1].value
                })
            }


        })

        if (error) {
            this.setState({
                loading: false,
                updateProcess: false,
                openModal: true,
                modalType: 'warning',
                modalMessage: t(messages.clipboardFormatError),
            });
        }
        else if (missingData) {
            this.setState({
                loading: false,
                updateProcess: false,
                openModal: true,
                modalType: 'warning',
                modalMessage: t(messages.lastDayCheck),
            });
        }
        else if (limitError) {
            this.setState({
                loading: false,
                updateProcess: false,
                openModal: true,
                modalType: 'warning',
                modalMessage: t('myData.limit'),
            });
        }
        else {
            const api_params = {
                "resource_id": resourceId,
                "resource_data": uploadedData,
                "start_date": moment(this.state.uploadEndDate, 'YYYY-MM-DD').subtract(30, 'days').format('YYYY-MM-DD'),
                "end_date": moment(this.state.uploadEndDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
            }

            this.props.updateHistoricalData(api_params).then(data => {
                if (data.success) {
                    this.setState({
                        updateProcess: false,
                        loading: false,
                        openModal: true,
                        overrideHeader: t('myData.updateSuccessHeader'),
                        modalType: 'success',
                        modalMessage: t(messages.updateDataSuccess),
                    });
                    this.props.removeCache(`${resourceId}-RESOURCE-`);
                    this.props.removeCache(`${resourceId}-RUN-`);
                }
                else if (data.missing) {
                    this.setState({
                        updateProcess: false,
                        loading: false,
                        openModal: true,
                        modalType: 'error',
                        modalMessage: t('myData.missingData').replace("###HOUR###", data.data),
                    });
                }
                else {
                    this.setState({
                        updateProcess: false,
                        loading: false,
                        openModal: true,
                        modalType: 'error',
                        modalMessage: t(messages.updateDataError),
                    });
                }
            })
        }

    }

    createExcelData = (columns, data) => {
        const csvData = [];

        data.forEach((r, index) => {
            const row = [];
            row.push(r.time);
            row.push(r.hour);
            row.push(r.demand.toFixed(2) * 1);
            row.push(r.outage.toFixed(2) * 1);
            csvData.push(row);
        });

        return [{ columns, data: csvData }];
    };

    getDaysArray = (start, end) => {
        for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
            arr.push(moment(dt).format("DD-MM-YYYY"));
        }
        return arr;
    };

    createUpdateData = (sheetData, lastDay) => {
        const { uploadStartDate, uploadEndDate } = this.state;
        const dates = this.getDaysArray(moment(uploadStartDate).format("YYYY-MM-DD"), moment(uploadEndDate).format("YYYY-MM-DD"));

        const data = [
            [
                { value: 'Date', readOnly: true },
                { value: 'Hour', readOnly: true },
                { value: 'Demand (MWh)', readOnly: true },
                { value: 'Outage (MWh)', readOnly: true }
            ]
        ]

        dates.forEach(x => {
            for (let i = 0; i < 24; i++) {
                let hour = i < 10 ? "0" + i + ":00:00" : i + ":00:00"
                data.push([
                    { value: x, readOnly: true },
                    { value: hour, readOnly: true },
                    { value: this.getValue(x, hour, sheetData, 2), readOnly: this.checkDate(x, i) },
                    { value: this.getValue(x, hour, sheetData, 3) }
                ])

            }
        })
        this.setState({ loading: false, uploadData: data, lastDay: lastDay })
    }

    getValue = (time, hour, data, valueIndex) => {
        const filtered = data.filter(x =>
            x[0].value === time && x[1].value === hour
        )
        if (filtered.length > 0) {
            return filtered[0][valueIndex].value
        }
        else {
            if (valueIndex === 3)
                return 0
            else
                return ''

        }
    }

    checkDate = (time, hour) => {
        const { allowSameDayUpdate, allowSameDayHourRestrict } = this.state;
        console.log(allowSameDayUpdate, allowSameDayHourRestrict);
        if (allowSameDayUpdate) {
            if (moment(time, "DD-MM-YYYY").isBefore(moment(new Date().getDate(), "DD-MM-YYYY")))
                return false;
            else if (moment(time, "DD-MM-YYYY").isSameOrBefore(moment(new Date().getDate(), "DD-MM-YYYY"))) {
                if (!allowSameDayHourRestrict) {
                    return false;
                }
                else {
                    return hour >= new Date().getHours();
                }
            }
            else {
                return true;
            }
        }
        else {
            return moment(time, "DD-MM-YYYY").isSameOrAfter(moment(new Date().getDate(), "DD-MM-YYYY"))

        }
    }

    checkHistoricalData = resourceId => {
        this.props.historicalDataCheck(resourceId).then(result => {
            if (result.success) {
                this.setState({
                    lastDay: result.last_day,
                });
            } else {
                this.setState({ lastDay: null });
            }
        });
    };

    getResourceSettings(resourceId) {
        const { i18n } = this.props;
        this.setState({ loading: true });
        this.props.fetchResourceSettings(resourceId).then(result => {
            if (result.success) {
                i18n.changeLanguage(result.lang);
                this.setState({
                    loading: false,
                    resourceId: resourceId,
                    allowSameDayUpdate: result.allow_same_day_update,
                    allowSameDayHourRestrict: result.allow_same_day_hour_restrict,
                    lowerLimit: result.lower_limit,
                    upperLimit: result.upper_limit
                })
            }
        });
    }

    render() {
        const { auth, openModal, modalMessage, modalType, overrideHeader, loading, viewStarDate, viewEndDate, uploadStartDate, uploadEndDate, myData, excelData, uploadData, updateProcess } = this.state;
        const { resources, t } = this.props;
        let resourceList = [];
        if (resources) {
            resources
                .map(r =>
                    resourceList.push({
                        key: r.id,
                        text: r.name,
                        value: r.id,
                    }),
                );
        }
        return (
            <div className="wrapper">
                <MonoHeader history={this.props.history} activeItem="mydata" />

                <Segment loading={loading} style={{ width: '100%', minHeight: window.screen.height - 400 }}>

                    {
                        !auth && !loading && (
                            <Message error>
                                <Message.Header>{t('auth')}</Message.Header>
                                <p>{t('authMessage')}</p>
                            </Message>
                        )
                    }

                    {
                        auth && (

                            <Grid columns={2} stackable>
                                <Grid.Column>
                                    <Segment raised>
                                        <Label ribbon color='teal'>View Data</Label>
                                        <div className="row-container">
                                            <div className="wp25">
                                                <Grid columns={2} divided>
                                                    <Grid.Column width={6}>{t('resource')}:</Grid.Column>
                                                </Grid>
                                            </div>
                                            <div className="wp68">
                                                <Dropdown
                                                    placeholder={t('resource')}
                                                    fluid
                                                    search
                                                    selection
                                                    value={this.state.resourceId}
                                                    options={resourceList}
                                                    onChange={(e, { value }) => {
                                                        this.setState({ resourceId: value });
                                                        this.checkHistoricalData(value);
                                                        this.getResourceSettings(value);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="h5" />

                                        <div className="row-container">
                                            <div className="wp25">{t('startDate')}:</div>
                                            <div className="wp25">
                                                <DatePicker
                                                    selected={viewStarDate}
                                                    onChange={date =>
                                                        this.setState({
                                                            viewStarDate: date,
                                                        })
                                                    }
                                                    maxDate={moment(new Date()).subtract(1, 'days').toDate()}
                                                    dateFormat="dd-MM-yyyy"
                                                    style={{ backgroundColor: 'green', width: '100%' }}
                                                    customInput={
                                                        <Input
                                                            className="wp65"
                                                            icon="calendar times"
                                                            iconPosition="left"
                                                            value={viewStarDate}
                                                        />
                                                    }
                                                />
                                            </div>

                                            <div className="wp20">{t('endDate')}:</div>
                                            <div className="wp25">
                                                <DatePicker
                                                    selected={viewEndDate}
                                                    onChange={date =>
                                                        this.setState({
                                                            viewEndDate: date,
                                                        })
                                                    }
                                                    maxDate={moment(new Date()).subtract(1, 'days').toDate()}
                                                    dateFormat="dd-MM-yyyy"
                                                    style={{ backgroundColor: 'green', width: '100%' }}
                                                    customInput={
                                                        <Input
                                                            className="wp65"
                                                            icon="calendar times"
                                                            iconPosition="left"
                                                            value={viewEndDate}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="h5" />
                                        <div className="row-container">
                                            <Button
                                                className="wp100"
                                                color="teal"
                                                onClick={(e) => this.searchData('VIEW')}
                                            >
                                                {t('submit')}
                                            </Button>
                                        </div>
                                        <div className="h5" />
                                        {myData.length > 0 && (
                                            <div className="row-container">
                                                <Table celled striped selectable role="grid" aria-labelledby="header">
                                                    <Table.Header className="thead-scroll">
                                                        <Table.Row>
                                                            <Table.HeaderCell key={0}>Date</Table.HeaderCell>
                                                            <Table.HeaderCell key={1}>Hour</Table.HeaderCell>
                                                            <Table.HeaderCell key={2}>Demand (MWh)</Table.HeaderCell>
                                                            <Table.HeaderCell key={3}>Outage (MWh)</Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>


                                                    <Table.Body className="tbody-scroll">
                                                        {myData.length > 0 &&
                                                            myData.map((r, index) => (
                                                                <Table.Row key={index}>
                                                                    <Table.Cell>{r.time}</Table.Cell>
                                                                    <Table.Cell>{r.hour}</Table.Cell>
                                                                    <Table.Cell>{r.demand}</Table.Cell>
                                                                    <Table.Cell>{r.outage}</Table.Cell>
                                                                </Table.Row>
                                                            ))}
                                                    </Table.Body>

                                                    <Table.Footer>
                                                        <Table.Row>
                                                            <Table.HeaderCell colSpan='4'>

                                                                <ExcelFile
                                                                    element={
                                                                        <Button floated='right' icon labelPosition="right" color="teal" className="w200">
                                                                            {t('download')}
                                                                            <Icon name="angle down" />
                                                                        </Button>
                                                                    }
                                                                    filename={`HistoricalData_${moment(viewStarDate).format('DD-MM-YYYY')}_${moment(viewEndDate).format('DD-MM-YYYY')}`}
                                                                >
                                                                    <ExcelSheet dataSet={excelData} name={t('oldData')} />
                                                                </ExcelFile>

                                                            </Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Footer>
                                                </Table>
                                            </div>
                                        )}
                                    </Segment>
                                </Grid.Column>

                                <Grid.Column>
                                    <Segment raised>
                                        <Label color='teal' ribbon>Update Data</Label>

                                        <div className="row-container">
                                            <div className="wp25">
                                                <Grid columns={2} divided>
                                                    <Grid.Column width={6}>{t('resource')}:</Grid.Column>
                                                </Grid>
                                            </div>
                                            <div className="wp68">
                                                <Dropdown
                                                    placeholder={t('resource')}
                                                    fluid
                                                    search
                                                    selection
                                                    value={this.state.resourceId}
                                                    options={resourceList}
                                                    onChange={(e, { value }) => {
                                                        this.setState({ resourceId: value });
                                                        this.checkHistoricalData(value);
                                                        this.getResourceSettings(value);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="h5" />

                                        <div className="row-container">
                                            <div className="wp25">{t('startDate')}:</div>
                                            <div className="wp25">
                                                <DatePicker
                                                    selected={uploadStartDate}
                                                    onChange={date =>
                                                        this.setState({
                                                            uploadStartDate: date,
                                                        })
                                                    }
                                                    maxDate={moment(new Date()).add(5, 'days').toDate()}
                                                    dateFormat="dd-MM-yyyy"
                                                    style={{ backgroundColor: 'green', width: '100%' }}
                                                    customInput={
                                                        <Input
                                                            className="wp65"
                                                            icon="calendar times"
                                                            iconPosition="left"
                                                            value={uploadStartDate}
                                                        />
                                                    }
                                                />
                                            </div>
                                            <div className="wp20">{t('endDate')}:</div>
                                            <div className="wp25">
                                                <DatePicker
                                                    selected={uploadEndDate}
                                                    onChange={date =>
                                                        this.setState({
                                                            uploadEndDate: date,
                                                        })
                                                    }
                                                    maxDate={moment(new Date()).add(5, 'days').toDate()}
                                                    dateFormat="dd-MM-yyyy"
                                                    style={{ backgroundColor: 'green', width: '100%' }}
                                                    customInput={
                                                        <Input
                                                            className="wp65"
                                                            icon="calendar times"
                                                            iconPosition="left"
                                                            value={uploadEndDate}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="h5" />
                                        <div className="row-container">
                                            <Button
                                                className="wp100"
                                                color="teal"
                                                onClick={(e) => this.searchData('UPDATE')}
                                            >
                                                {t('submit')}
                                            </Button>
                                        </div>

                                        <div className="h5" />
                                        <DataSheet
                                            data={uploadData}
                                            valueRenderer={cell => cell.value}
                                            parsePaste={clipboardData => {
                                                return clipboardData
                                                    .split(/\r\n|\n|\r/)
                                                    .filter(row => row.length > 0)
                                                    .map(row => row.split("\t"));
                                            }}
                                            attributesRenderer={(cell) => { return { 'data-hint': cell.hint || {} } }}
                                            onCellsChanged={changes => {
                                                const uploadData = this.state.uploadData.map(row => [...row])
                                                let error = false
                                                changes.forEach(({ cell, row, col, value }) => {
                                                    try {
                                                        const dotLength = value.split('.').length - 1
                                                        const commaLength = value.split(',').length - 1
                                                        if (commaLength > 0 || dotLength > 1) {
                                                            error = true
                                                            cell.hint = 'not-valid'
                                                        }
                                                        else {
                                                            cell.hint = ""
                                                        }
                                                        uploadData[row][0].updated = true
                                                        uploadData[row][col] = { ...uploadData[row][col], value }
                                                    } catch (error) {
                                                        alert("Incorrect data entry!")
                                                    }

                                                })
                                                if (error) {
                                                    this.setState({
                                                        openModal: true,
                                                        modalType: 'warning',
                                                        modalMessage: t(messages.clipboardFormatError),
                                                    });
                                                }
                                                this.setState({ uploadData })
                                            }}
                                        />

                                        <div className="row-container">
                                            <Button
                                                disabled={updateProcess}
                                                className="wp100"
                                                color="teal"
                                                onClick={(e) => this.updateData()}
                                            >
                                                Update
                                    </Button>
                                        </div>
                                    </Segment>
                                </Grid.Column>

                            </Grid>

                        )}
                </Segment>
                <Footer />
                <Alert
                    openModal={openModal}
                    overrideHeader={overrideHeader}
                    modalMessage={modalMessage}
                    type={modalType}
                    closeModal={this.closeModal}
                />
            </div >
        );
    }
}

const mapStateToProps = state => {
    const { id, resources } = state.user;
    return { id, resources };
};

export default withTranslation()(connect(mapStateToProps, {
    getHistoricalData,
    updateHistoricalData,
    fetchResourceSettings,
    fetchUser,
    historicalDataCheck,
    removeCache,
    checkPageAuth
})(MyData));